import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import theme from 'src/styles/theme';
import vw, { vwDesktop } from 'src/styles/utils';

import Calendar from 'src/assets/images/icons/calendar.png';

const BookAppointment = (props) => {
  return (
    <Intl>
      {(t, { locale }) => (
        <Root
          href="https://app.acuityscheduling.com/schedule.php?owner=20816987&appointmentType=32704199"
          target="_blank"
        >
          <Img src={Calendar} alt="Calendar icon" width="32" height="32" />
          {t.bookAppointment}
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.a`
  color: white;
  position: fixed;
  ${vw('line-height', 15, 20)};
  ${vw('letter-spacing', 0.5, 1)};
  ${vw('font-size', 11, 13)};
  ${vw('width', 161, 227)};
  ${vw('height', 48, 59)};
  background-color: ${theme.color.blue};
  bottom: 0;
  ${vw('border-top-right-radius', 14, 24)};
  ${vw('border-top-left-radius', 14, 24)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  left: 0;
  right: 0;
  margin: auto;

  @media ${media.tablet} {
    left: auto;
    ${vw('right', 80)};
  }
`;

const Img = styled.img`
  margin-right: 12px;
`;

export default BookAppointment;
