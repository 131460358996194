import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import PhotoPortrait from 'src/components/PhotoPortrait';
import Button from 'src/components/Button';

import Trees from 'src/assets/images/elements/cc_trees.svg';

import vw from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

function BannerPortraitLeft(props) {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <Article>
            <h1>{props.title}</h1>
            <p className="body">
              {props.body}
            </p>
            {
              props.buttonLable &&
              <Button to={props.buttonLink} text={props.buttonLabel} />
            }
          </Article>
          <StyledPhotoPortrait src={props.image} />
          <Watermark src={Trees} />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  background-color: ${theme.color.cc_off_white_bg};
  ${vw('padding-top', 1, 60, 0)}
  ${vw('padding-bottom', 58, 105, 130)}
  ${vw('padding-left', 32, 61, 101)}
  ${vw('padding-right', 32, 173, 196)}
  z-index: 0;
  position: relative;

  @media ${media.desktop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const Article = styled.div`
  position: relative;
  z-index: 1;
  ${vw('width', 256, 530, 400)}
  ${vw('margin-top', 0, 30, 0)}

  p {
    ${vw('margin-bottom', 0, 30, 40)}
  }
`;

const StyledPhotoPortrait = styled(PhotoPortrait)`
  ${vw('margin-top', 16, 64, 120)};
`;

const Watermark = styled.img`
  opacity: 0.6;
  position: absolute;
  z-index: 0;
  right: 0;
  bottom: 0;
  ${vw('width', 400)}
  ${vw('height', 475)}
  display: none;

  @media ${media.tablet} {
    display: unset;
  }
`;

export default BannerPortraitLeft;
