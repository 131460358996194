import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

function PhotoLandscape(props) {
  const { src } = props;
  return (
    <Root src={src} {...props} ></Root>
  );
};

const Root = styled.img`
  ${vw('width', 288, 648, 1280)}
  ${vw('height', 166, 375, 740)}
  margin-left: auto;
  margin-right: auto;
  display: block;
  object-fit: cover;
  position: relative;
`;

export default PhotoLandscape;
