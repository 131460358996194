import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

// Icons
import ArrowLeft from 'src/components/icons/ArrowLeft';
import ArrowRight from 'src/components/icons/ArrowRight';
import CloseIcon from 'src/assets/images/icons/close.svg';

// Styles
import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

// Slick styles
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Spotlight = (props) => {
  const {title, images, closeHandler, index} = props;
  const [slickReady, setSlickReady] = useState(false);
  const slickRef = useRef(null);

  useEffect(() => {
    slickRef.current.slickGoTo(index);
    setTimeout(() => setSlickReady(true), 300);
  });

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <StyledArrowRight />,
    prevArrow: <StyledArrowLeft />
  };
  
  return (
    <Root ready={slickReady}>
      <Title ready={slickReady}>{title}</Title>
      <Close
        onClick={closeHandler}
        src={CloseIcon}
        width="20"
        height="20"
      />
      <Slider
        {...settings}
        ref={slickRef}
      >
        {
          images.map((image, index) => (
          <Card key={index}>
            <Img src={image.full} />
            <p className="caption">
              <span>{image.caption}</span>
              <span>{index+1}/{images.length}</span>
            </p>
          </Card>
          ))
        }
      </Slider>
    </Root>
  );
};

export default Spotlight;

const Root = styled.div`
  background-image: url(${require('src/assets/images/woodgrain-background.jpg')});
  background-position: cover;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 99;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-top', 60, 0)}

  @media ${media.tablet} {
    justify-content: center;
  }

  .slick-slider {
    width: 100%;
    opacity: ${props => props.ready ? 1 : 0};
    transition: 0.4s ease;
  }
`;

const Title = styled.h3`
  font-weight: 300;
  text-transform: uppercase;
  color: ${theme.color.cc_grey};
  ${vw('margin-bottom', 90, 156, 40)}
  ${vw('margin-left', 10)}
  ${vw('margin-right', 10)}
  ${vw('font-size', 18)}
  ${vw('line-height', 24)}
  ${vw('letter-spacing', 7)}
  text-align: center;

  opacity: ${props => props.ready ? 1 : 0};
  transition: 0.4s ease;

  @media ${media.tablet} {
    text-align: center;
  }
`;

const Card = styled.div`
  p {
    display: flex;
    justify-content: space-between;
    ${vw('width', 256, 648, 838)};
    margin: auto;
  }
`;

const Img = styled.img`
  width: ${vwMobile(256)};
  height: auto;
  margin: auto;
  ${vw('margin-bottom', 60, 120, 12)};
  filter: drop-shadow(0px 2px 12px rgba(75, 78, 82, 0.4008));
  object-fit: cover;
  
  @media ${media.tablet} {
    width: auto;
    height: ${vwTablet(433)};
    max-width: ${vwTablet(648)};
  }

  @media ${media.desktop} {
    height: ${vwDesktop(560)}
  }
`;

const Close = styled.img`
  cursor: pointer;
  position: absolute;
  ${vw('width', 16, 20)}
  ${vw('top', 16, 20)}
  ${vw('top', 16, 36)}
  ${vw('right', 16, 36)}
`;

const StyledArrowLeft = styled(ArrowLeft)`
  &.slick-prev {
    position: absolute;
    left: 45px;
    ${vw('left', 10, 25, 45)}
    ${vw('top', 90, 230, 300)}
    z-index: 10;
  }
`;

const StyledArrowRight = styled(ArrowRight)`
  &.slick-next {
    position: absolute;
    ${vw('right', 10, 25, 45)}
    ${vw('top', 90, 230, 300)}
    z-index: 10;
  }
`;
