export default [
  {
    titleId: 'ground',
    levelImage: require('src/assets/images/amenities/birdeyes/03_ground_level.png'),
    hotSpots: [
      {
        positions: [467, 431],
        image: require('src/assets/images/gallery/exterior/gallery_02.jpg'),
        titleId: 'lobby'
      },
      {
        positions: [1287, 449],
        image: require('src/assets/images/gallery/exterior/gallery_04.jpg'),
        titleId: 'rooftop'
      },
      {
        positions: [1076, 844],
        image: require('src/assets/images/gallery/exterior/gallery_03.jpg'),
        titleId: 'autoCourt'
      },
    ]
  },
  {
    titleId: 'lobby',
    levelImage: require('src/assets/images/amenities/birdeyes/01_lobby_level.png'),
    hotSpots: [
      {
        positions: [374, 593],
        image: require('src/assets/images/gallery/interior/gallery_02.jpg'),
        titleId: 'grandLobbyEntrance'
      },
      {
        positions: [893, 686],
        image: require('src/assets/images/gallery/interior/gallery_03.jpg'),
        titleId: 'concierge'
      },
      {
        positions: [1397, 846],
        image: require('src/assets/images/gallery/interior/gallery_06.jpg'),
        titleId: 'fitnessCentre'
      },
    ]
  },
  {
    titleId: 'amenity',
    levelImage: require('src/assets/images/amenities/birdeyes/02_amenity_level.png'),
    hotSpots: [
      {
        positions: [633, 537],
        image: require('src/assets/images/gallery/interior/gallery_04.jpg'),
        titleId: "billards"
      },
      {
        positions: [521, 785],
        image: require('src/assets/images/gallery/interior/gallery_05.jpg'),
        titleId: "kitchen"
      },
      {
        positions: [1226, 336],
        image: require('src/assets/images/gallery/exterior/gallery_05.jpg'),
        titleId: "outdoorLounge"
      },
    ]
  }
];
