import React from 'react';

export default [
  {
    text: <>ENTRANCE</>,
    top: '53.7%',
    left: '9%',
    order: 1,
    textId: 'entrance'
  },
  {
    text: (
      <>
        BIKE RACKS
      </>
    ),
    top: '57.2%',
    left: '5.7%',
    bottomText: true,
    order: 2,
    textId: 'bikeRacks'
  },
  {
    text: (
      <>
        PARKADE ENTRANCE
      </>
    ),
    top: '37.9%',
    left: '50.1%',
    order: 3,
    textId: 'parkadeEntrance'
  },
  { 
    text: (
      <>
        CHILDREN’S PLAY AREA
      </>
    ),
    top: '19.2%',
    left: '57.8%',
    order: 4,
    textId: 'childrensPlayArea'
  },
  {
    text: (
      <>
        PICNIC TABLES
      </>
    ),
    top: '12.6%',
    left: '26.2%',
    leftText: true,
    order: 5,
    textId: 'picnicTables'
  },
  {
    text: (
      <>
        GARDEN PLOTS
      </>
    ),
    top: '10.3%',
    left: '34.7%',
    order: 6,
    textId: 'gardenPlots'
  },

  // End of the feature page text banner data (manually sorted)
  {
    text: (
      <>
        GARDEN PLOTS
      </>
    ),
    top: '10.3%',
    left: '68.9%',
    order: 6
  },
  {
    text: (
      <>
        PICNIC
        <br />
        TABLES
      </>
    ),
    top: '23.4%',
    left: '71.2%',
    rightText: true,
    order: 5
  },
  {
    text: <>ENTRANCE</>,
    top: '58.1%',
    left: '91%',
    order: 1
  },
  {
    text: (
      <>
        BIKE RACKS
      </>
    ),
    top: '61.2%',
    left: '94.7%',
    bottomText: true,
    order: 2
  },
];
