import React from 'react';
import styled from 'styled-components';

import FloorplansWrapper from './FloorplansWrapper';
import TableHeader from './TableHeader';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const FloorplansTable = () => (
  <Root>
    <TableHeader />
    <FloorplansWrapper />
  </Root>
);

const Root = styled.div`
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #6BA4B8;

  ${vw('width', 288, 708, 1061)};
  padding: 0 ${vwMobile(10)} ${vwMobile(10)};


  @media ${media.tablet} {
    padding: ${vwTablet(60)} ${vwTablet(28)};
    margin: 0 ${vwTablet(-30)};
  }

  @media ${media.desktop} {
    margin-top: ${vwDesktop(120)};
    margin-left: auto;
    margin-right: auto;
    padding: ${vwDesktop(60)};
  }
`;

export default FloorplansTable;
