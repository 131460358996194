import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
let clientX, deltaX, angle;

const View360 = (props) => {
  const { src, defaultAngle, offset, speed, onUpdateAngle, onUpdate, onUpdateEnd } = props;
  const rootRef = useRef(null);

  useEffect(() => {
    rootRef.current.style.backgroundPositionX = 0;
    angle = defaultAngle + offset;
    onUpdateAngle(angle)
  }, [])

  const handleTouchStart = (e) => {
    clientX = e.touches[0].clientX;
  }

  const handleTouchMove = (e) => {
    let slidePositionXValue = parseInt(rootRef.current.style.backgroundPositionX, 10);

    deltaX = speed*(e.changedTouches[0].clientX - clientX);
    rootRef.current.style.backgroundPositionX = `${deltaX+slidePositionXValue}px`;

    calculateAngle(-1*(deltaX+slidePositionXValue));

    onUpdate();
  }

  const handleTouchEnd = () => {
    onUpdateEnd();
  }

  const calculateAngle = (nowPositionX) => {
    var image = new Image();
    image.src = src;
    angle = (360*nowPositionX/image.width%360+offset);

    onUpdateAngle(angle)
  }

  return (
    <Root 
      innerRef={rootRef}
      backgroundImage={src}  
      onTouchStart={handleTouchStart} 
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
    </Root>
  );
};

View360.propTypes = {
  src: PropTypes.string,
  defaultAngle: PropTypes.number,
  speed: PropTypes.number,
}

View360.defaultProps = {
  defaultAngle: 0,
  offset: 0,
  speed: 1,
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${props => `url(${props.backgroundImage})`};
  background-size: cover;
  background-repeat: repeat-x;
`;

export default View360;
