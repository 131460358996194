import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const Checkbox = (props) => {
  const { label, name, id } = props;
  return (
    <Root>
      <input name={name} id={id} required type="checkbox" />
      <div className="check">
        <img
          src={require('src/assets/images/icons/checkmark.svg')}
          alt="Checkmark"
        />
      </div>
      <label htmlFor={id} className="disclaimer">
        {label}
      </label>
    </Root>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};

const Root = styled.div`
  width: 100%;
  position: relative;
  ${vw('margin-bottom', 40, 40)}
  ${vw('margin-top', 0, 0, 16)}
  ${vw('padding-left', 32)}
  @media ${media.desktop} {
    max-width: ${vwDesktop(800)};
    margin-left: auto;
    margin-right: auto;
  }
  label {
    display: block;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    ${vw('width', 20)}
    ${vw('height', 20)}
    &:checked ~ .check img {
      opacity: 1;
    }
  }
  .check {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    border: 1px solid ${theme.color.grey_dark};
    display: flex;
    align-items: center;
    justify-content: center;
    ${vw('width', 20)}
    ${vw('height', 20)}
    border-radius: 4px;
    
    img {
      display: block;
      height: auto;
      opacity: 0;
      ${vw('width', 12)}
    }
  }
`;

export default Checkbox;
