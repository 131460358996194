import React from 'react';
import styled from 'styled-components';

import data from 'src/data/companies';

import Intl from 'src/components/Intl';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const Partners = () => {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
    <Root>
      {
        data.map((company, index) => (
          <Company className="company">
            {/* Logo */}
            <LogoWrapper>
              <LogoLink>
                <Logo
                  src={require(`src/assets/images/developer/${company.logo}`)}
                  height="50"
                  mobileHeight={company.logoHeightMobile}
                  desktopHeight={company.logoHeightDesktop}
                />
              </LogoLink>
            </LogoWrapper>

            <Body>
              {/* Name */}
              <Name>{company.name}</Name>
              {/* Description */}
              <p className="body">{t.developer.companies[company.id].description}</p>
            </Body>
          </Company>
        ))
      }
    </Root>
          )}
    </Intl>
  );
};

export default Partners;

const Root = styled.div`
  position: relative;
  ${vw('padding-left', 17, 60, 80)};
  ${vw('padding-right', 17, 60, 80)};
`;

const Company = styled.div`
  ${vw('padding-top', 32, 60, 80)}
  ${vw('padding-bottom', 32, 60, 80)}

  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
  }
`;

const LogoWrapper = styled.div`
  display: block;
  border-bottom: 1px solid ${theme.color.blue};
  ${vw('padding-bottom', 20, 29, 0)}
  ${vw('margin-bottom', 20, 40, 0)}
  width: ${vwMobile(286)};

  @media ${media.tablet} {
    width: ${vwTablet(648)};
  }

  @media ${media.desktop} {
    width: ${vwDesktop(378)};
  }
`;

const Logo = styled.img`
  height: ${(props) => props.mobileHeight}px;

  @media ${media.tablet} {
    height: ${(props) => props.desktopHeight}px;
  }
`;

const LogoLink = styled.div``;

const Body = styled.div`
  width: ${vwMobile(286)};

  @media ${media.tablet} {
    width: ${vwTablet(648)};
  }

  @media ${media.desktop} {
    width: ${vwDesktop(843)};
  }
`;

const Name = styled.h3`
  color: ${theme.color.blue};
  ${vw('font-size', 18, 21)}
  font-weight: bold;
`;
