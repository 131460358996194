// Packages
import React from 'react';
import styled from 'styled-components';

// Components
import Intl from 'src/components/Intl';
import Button from 'src/components/Button';
import PhotoLandscape from 'src/components/PhotoLandscape';

// Styles
import vw from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

function BannerLandscapeRight(props) {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          
          <Article>
            {
              props.title &&
              <h1>
                {props.title}
              </h1>
            }
            {
              props.body &&
              <p className="body">
                {props.body}
              </p>
            }
            {
              props.buttonLable &&
              <Button  to={`${getLocaleURL()}/${props.buttonLink}`} text={props.buttonLable} />
            }
          </Article>

          {
            props.videoSrc && 
            <video src={props.videoSrc} autoPlay muted playsInline loop />
          }
          {
            props.image &&
            <PhotoLandscape src={props.image} />
          }
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  background-color: ${theme.color.cc_off_white_bg};
  ${vw('padding-top', 64, 120)}
  ${vw('padding-bottom', 0, 120, 130)}
  ${vw('padding-left', 32, 60, 207)}
  ${vw('padding-right', 32, 60, 101)}
  position: relative;
  
  @media ${media.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  video {
    ${vw('width', 256, 647, 620)}
    ${vw('height', 198, 500, 480)}
    border: 8px solid white;
    ${vw('border-width', 8, 12, 14)}
    object-fit: cover;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    filter: drop-shadow(0px 2px 14px rgba(75, 78, 82, 0.10));
  }

  h1 {
    ${vw('font-size', 24, 36)}
  }
`;

const Article = styled.div`
  position: relative;
  z-index: 1;
  ${vw('width', 256, 534, 440)}
  ${vw('margin-top', 16, 30, 150)}
  ${vw('margin-bottom', 16, 30, 0)};

  p {
    margin-right: 0;
    ${vw('margin-bottom', 40)}
  }
`;

export default BannerLandscapeRight;
