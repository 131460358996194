import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'src/components/Button';

import vw from 'src/styles/utils';

const CenterBlock = (props) => {
  const { title, text, intro, buttonTo, buttonLabel, download } = props;

  return (
    <Root>
    {
      title &&
      <h2>{title}</h2>
    }

    {
      text && 
      <p className="body">{text}</p>
    }

    {
      intro &&
      <p className="intro">{intro}</p>
    }

    {
      (buttonLabel && !download) &&
      <Button
        to={buttonTo}
        text={buttonLabel}
      />
    }

    {
      download &&
      <DownloadButton
        href={buttonTo}
        className="button"
        target="_blank"
      >
        {buttonLabel}
      </DownloadButton>
    }
    </Root>
  )
};

CenterBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  intro: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonTo: PropTypes.string
};

const Root = styled.div`
  text-align: center;
  ${vw('padding-top', 40, 60, 80)}
  ${vw('padding-bottom', 40, 60, 80)}

  h2 {
    margin-left: auto;
    margin-right: auto;
    ${vw('width', 288, 650)}
  }

  p {
    margin-left: auto;
    margin-right: auto;
    ${vw('margin-top', 20, 40)}
    ${vw('margin-bottom', 20, 40)}
    ${vw('width', 288, 480)}
  }
`;

const DownloadButton = styled.a``;

export default CenterBlock; 
