import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Intl from 'src/components/Intl';
import LanguageSwitcher from './LanguageSwitcher';
import Button from './Button';
import FullLogo from './FullLogo';
import CompactLogo from './CompactLogo';
import useScrollDown from 'components/hooks/useScrollDown';
import MenuToggle from 'src/components/icons/MenuToggle';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

function Header() {
  const [opened, setOpened] = useState(false)
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const hasPageLoaded = useSelector((state) => state.load.hasLoaded);
  const isScrollingDown = useScrollDown(window.innerHeight * 1.3 || 900);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (hasPageLoaded) {
      setTimeout(() => setHasLoaded(true), 2000);
    }
  }, [hasPageLoaded]);

  const handleScroll = (e) => {
    // checks to see if user has scrolled past 50px
    if (e.target.scrollingElement.scrollTop > 50) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  const toggleOpen = () => 
    setOpened(!opened)

  const closeMenu = () => {
    setOpened(false)
  }

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root
          sticky={hasScrolled}
          opened={opened}
          style={{
            transform:
              isScrollingDown || !hasLoaded
                ? 'translateY(-10%)'
                : 'translateY(0)',
            opacity: hasLoaded ? 1 : 0,
          }}
        >
          <LogoWrapper hasScrolled={hasScrolled}>
            <Link to={getLocaleURL()}>
              <Logo
                src={require('src/assets/images/logos/precidia-logo-blue.svg')}
                alt="Precidia"
              />
            </Link>
          </LogoWrapper>

          {/* Tablet & Mobile Menu */}
          <MenuToggle
            onClick={toggleOpen}
            className="menu-toggle"
            menuOpen={opened}
            style={{
              opacity: hasLoaded ? 1 : 0,
            }}
          />
          <RegisterButton
            onClick={closeMenu}
            className="button hide-on-desktop"
            to={`${getLocaleURL()}/register`}
          >
            {t.pages.register}
          </RegisterButton>
          <MenuWrapper 
            opened={opened}
            style={{
              opacity: hasLoaded ? 1 : 0,
            }}
          >
            <LanguageSwitcher className="show-on-desktop language-switcher"/>
            <nav>
              <StyledLink onClick={toggleOpen} to={`${getLocaleURL()}/floorplans`}>{t.pages.floorplans}</StyledLink>
              <StyledLink onClick={toggleOpen}  to={`${getLocaleURL()}/interiors`}>{t.pages.interiors}</StyledLink>
              <StyledLink onClick={toggleOpen}  to={`${getLocaleURL()}/amenities`}>{t.pages.amenities}</StyledLink>
              <StyledLink onClick={toggleOpen}  to={`${getLocaleURL()}/neighbourhood`}>{t.pages.neighbourhood}</StyledLink>
              <StyledLink onClick={toggleOpen}  to={`${getLocaleURL()}/gallery`}>{t.pages.gallery}</StyledLink>
              <StyledLink onClick={toggleOpen}  to={`${getLocaleURL()}/developer`}>{t.pages.developer}</StyledLink>
              <RegisterButton
                onClick={closeMenu}
                className="button show-on-desktop"
                to={`${getLocaleURL()}/register`}
              >
                {t.pages.register}
              </RegisterButton>  
            </nav>
            <LanguageSwitcher onClick={toggleOpen} className="show-on-tablet" />
          </MenuWrapper>
        </Root>
      )}
    </Intl>
  );
}

export default Header;

const Root = styled.header`
  display: flex;
  align-items: center;
  top: 0;
  transition: 0.7s ease;
  z-index: 90;
  width: 100%;
  ${vw('padding-left', 16, 24, 60)}
  ${vw('padding-right', 16, 24, 60)}
  ${vw('padding-top', 24, 32, 24)}
  ${vw('padding-bottom', 24, 32, 29)}
  position: fixed;
  background-color: ${(props) => props.sticky ? '#ffffff' : 'transparent'};
  box-shadow: ${(props) => props.sticky ? '0px 2px 14px rgba(75, 78, 82, 0.100705);' : 'none'};
  
  @media ${media.desktop} {
    background-color: ${(props) => props.sticky ? '#ffffff' : 'transparent'};
    bottom: unset;
    height: ${props => props.sticky ? vwDesktop(105) : vwDesktop(154)};
    justify-content: space-between;
    text-align: center;
    align-items: ${props => props.sticky ? 'center' : 'flex-start'};
    padding-top: ${props => props.sticky ? vwDesktop(32) : vwDesktop(24)};
    padding-bottom: ${props => props.sticky ? vwDesktop(28) : vwDesktop(29)};
  }

  ${props => props.opened &&
    `
      display: unset;
      position: fixed;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url(${require('src/assets/images/woodgrain-background.jpg')});
      background-position: cover;
      background-size: 100% 100%;
      align-items: unset;
      
      @media ${media.desktop} {
        display: flex;
        align-items: flex-start;
        background: none;
        background-color: ${props.sticky ? '#ffffff' : 'transparent'};
      }
    `
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${media.desktop} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: ${props => props.sticky ? 0 : vwDesktop(20)};
    }
  }

  .menu-toggle {
    @media ${media.desktop} {
      display: none;
    }
  }

  .language-switcher {
    opacity: ${props => props.sticky ? '0' : '1'};
    position: ${props => props.sticky ? 'fixed' : 'relative'};
    top: ${props => props.sticky ? '-100px' : 'unset'};
    transition: 2.2s ease;
  }
`;

const LogoLink = styled(Link)`
  display: inline-flex;
  align-items: center;

  @media ${media.desktop} {
    grid-column: 0 / 1;
    grid-row: 1 / 3;
  }
`;

const LogoWrapper = styled.div`
  position: relative;
  transition: 0.4s ease;
  overflow: hidden;
  ${vw('height', 62, 106, 106)}
  ${vw('width', 116, 197)}
  ${(props) =>
    props.hasScrolled
      ? vw('max-height', 25, 40, 40)
      : vw('max-height', 62, 106, 106)}
`;

const Logo = styled.img`
  display: block;
  /* height: auto; */
  position: absolute;
  bottom: 0;
  left: 0;
  transition: ${({ theme }) => theme.transition};
  ${vw('width', 116, 197)}
  ${vw('height', 62.25, 106)}
`;

const MenuWrapper = styled.div`
  transition: 0.2s all ease;
  position: relative;
  margin-top: 60px;
  border-top: 1px solid rgba(107, 164, 184, 0.8);
  width: ${vwMobile(180)};
  margin-left: auto;
  margin-right: auto;

  @media ${media.tablet} {
    margin-top: 130px;
  }


  @media ${media.tablet} {
    width: ${vwTablet(326)};
  }

  @media ${media.desktop} {
    border: none;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    width: auto;
    margin-left: unset;
    margin-right: unset;
  }

  ${props => !props.opened &&
    `display: none;`
  }
`;

const StyledLink = styled(NavLink)`
  text-transform: uppercase;
  font-weight: normal;
  ${vw('font-size', 13)}
  ${vw('line-height', 20)}
  ${vw('letter-spacing', 1)}
  border-bottom: 1px solid rgba(107, 164, 184, 0.8);
  padding-top: ${vwMobile(12)};
  padding-bottom: ${vwMobile(12)};
  text-align: center;
  width: 100%;
  color: ${theme.color.blue};
  transition: ${({ theme }) => theme.transition};

  @media ${media.tablet} {
    padding-top: ${vwTablet(20)};
    padding-bottom: ${vwTablet(20)};
  }

  @media ${media.desktop} {
    color: ${({ theme }) => theme.color.grey_dark};
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    ${vw('margin-right', 36)}
  }

  &:hover,
  &.active {
    background-color: rgba(107, 164, 184, 0.1);

    @media ${media.desktop} {
      color: ${theme.color.blue};
      background-color: unset;
    }
  }
`;

const RegisterButton = styled(Link)`
  position: absolute;
  ${vw('top', 24, 32)}
  ${vw('right', 16, 24)}

  &.show-on-desktop {
    display: none;
  }
  
  @media ${media.desktop} {
    position: relative;
    top: 0;
    right: 0;
    
    &.hide-on-desktop {
      display: none;
    }

    &.show-on-desktop {
      display: unset;
    }
  }

`;
