import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

// Icons
import ArrowLeft from 'src/components/icons/ArrowLeft';
import ArrowRight from 'src/components/icons/ArrowRight';

// Styles
import vw from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

// Slick styles
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Slick = (props) => {

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
    ]
  };

  return (
    <Root>
      <Title>{props.title}</Title>
      <Slider {...settings}>
        {
          props.images.map((image, index) => (
          <Card key={index} >
            <CardContent>
              <CardImage className="card-image" bg={image.image}></CardImage>
            </CardContent>

            <p className="caption">{image.text}</p>
          </Card>
          ))
        }
      </Slider>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${theme.color.cc_off_white_bg};
  ${vw('padding-left', 15, 0)}
  ${vw('padding-right', 15, 0)}
  ${vw('padding-top', 40, 60, 80)}
  
  .slick-slider {
    ${vw('margin-left', 0, -15)}
    ${vw('margin-right', 0, -15)}
  }

  .caption {
    text-align: left;
  }
`;

const Title = styled.h3`
  font-weight: 300;
  text-transform: uppercase;
  color: ${theme.color.cc_grey};
  ${vw('margin-bottom', 16, 30)}
  ${vw('font-size', 18)}
  ${vw('line-height', 24)}
  ${vw('letter-spacing', 7)}
  text-align: center;

  @media ${media.tablet} {
    text-align: left;
  }
`;

const Card = styled.div`
  ${vw('padding-left', 5, 10)}
  ${vw('padding-right', 5, 10)}
  text-align: center;
`;

const CardContent = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  ${vw('height', 200, 320, 400)}
  ${vw('margin-bottom', 8, 12)}
`

const CardImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.bg});
  background-position: center;
  background-size: cover;
  transition: all 0.8s ease 0s;
`;

export default Slick;
