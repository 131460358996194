import React from 'react';
import styled from 'styled-components';

import Hero from './Hero';
import Companies from './Companies';

import vw from 'src/styles/utils';

const Developer = () => {
  return (
    <Root>
      <Hero />
      <Companies />
    </Root>
  );
};

export default Developer;

const Root = styled.main`
  ${vw('padding-bottom', 60, 80)}
`;
