import React from 'react';
import styled from 'styled-components';

// Icon
import CloseIcon from 'src/assets/images/icons/close.svg';

// Styles
import vw from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

const Spotlight = (props) => {
  const {title, image, closeHandler} = props;

  return (
    <Root>
      <Title>{title}</Title>
      <Close
        onClick={closeHandler}
        src={CloseIcon}
        width="20"
        height="20"
      />
      <Card>
        <Img src={image} />
      </Card>
    </Root>
  );
};

export default Spotlight;

const Root = styled.div`
  background-image: url(${require('src/assets/images/woodgrain-background.jpg')});
  background-position: cover;
  background-size: 100% 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 99;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-top', 60, 0)}

  @media ${media.tablet} {
    justify-content: center;
  }

  .slick-slider {
    width: 100%;
  }
`;

const Title = styled.h3`
  font-weight: 300;
  text-transform: uppercase;
  color: ${theme.color.cc_grey};
  ${vw('margin-bottom', 90, 156, 40)}
  ${vw('margin-left', 10)}
  ${vw('margin-right', 10)}
  ${vw('font-size', 18)}
  ${vw('line-height', 24)}
  ${vw('letter-spacing', 7)}
  text-align: center;

  @media ${media.tablet} {
    text-align: center;
  }
`;

const Card = styled.div`
  p {
    display: flex;
    justify-content: space-between;
    ${vw('width', 256, 648, 838)};
    margin: auto;
  }
`;

const Img = styled.img`
  ${vw('width', 256, 648, 838)};
  ${vw('height', 180, 433, 560)};
  margin: auto;
  ${vw('margin-bottom', 60, 120, 12)};
  filter: drop-shadow(0px 2px 12px rgba(75, 78, 82, 0.4008));
  object-fit: cover;
`;

const Close = styled.img`
  cursor: pointer;
  position: absolute;
  ${vw('width', 16, 20)}
  ${vw('top', 16, 20)}
  ${vw('top', 16, 36)}
  ${vw('right', 16, 36)}
`;
