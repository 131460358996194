import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const MarbleBlock = (props) => {
  return (
    <Root {...props}>
      {
        props.children
      }
    </Root>
  );
};

const Root = styled.div`
  ${vw('padding-top', 160, 228, 282)}
  ${vw('padding-bottom', 42, 120, 160)}
  background-image: url(${require('src/assets/images/woodgrain-background.jpg')});
  background-position: cover;
  background-size: 100% 100%;
`;

export default MarbleBlock;
