import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Hero from './sections/Hero';
import PhotoLandscape from 'src/components/PhotoLandscape';
import CenterBlock from 'src/components/CenterBlock';
import ContentBlock from 'src/components/ContentBlock';

import LandscapePhoto from 'src/assets/images/gallery/interior/gallery_01.jpg';

import vw from 'src/styles/utils';

const Home = () => {
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root className="page">
          <Hero />
          <StyledPhotoLandscape src={LandscapePhoto} className="photolandscape" />
          <CenterBlock
            title={t.home.content1.title}
            text={t.home.content1.text}
            buttonLabel={t.home.content1.button}
            buttonTo={`${getLocaleURL()}/interiors`}
          />
          <ContentBlock
            title={t.home.content2.title}
            text={t.home.content2.text}
            image={require('src/assets/images/homepage/amenities.jpg')}
            buttonLabel={t.home.content2.button}
            buttonTo={`${getLocaleURL()}/amenities`}
            reverse
          />
          <ContentBlock
            title={t.home.content3.title}
            text={t.home.content3.text}
            video="https://player.vimeo.com/progressive_redirect/playback/671283944/rendition/540p/540p.mp4?loc=external&signature=c18412f5c27a719fc49f285116dd34fcfe7197e88dc2176489306be0b91cf40a"
            buttonLabel={t.home.content3.button}
            buttonTo={`${getLocaleURL()}/neighbourhood`}
          />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div``;

const StyledPhotoLandscape = styled(PhotoLandscape)`
  ${vw('margin-top', 40, 60, 160)}
`;

export default Home;
