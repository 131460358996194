import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import Intl from 'src/components/Intl';
import CloseIcon from './CloseIcon';
import SubitemList from './SubitemList';

// Assets
import NeighbourhoodImage from 'src/assets/images/neighbourhood/map.png';

// Data
import data from 'src/data/neighbourhood';

// Styles
import theme from 'src/styles/theme';
import vw, { vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const NeighbourhoodMap = () => {
  const [activeOrder, setActiveOrder] = useState(null);
  const [activeCategory, setActiveCategory] = useState(0);

  const mouseEnterHandler = (order) => {
    setActiveOrder(order);
  };

  const mouseLeaveHandler = () => {
    setActiveOrder(null);
  };

  const categoryClickHandler = (categoryClickedIndex) => {
    if (activeCategory === categoryClickedIndex) {
      setActiveCategory(undefined)
    } else {
      setActiveCategory(categoryClickedIndex)
    }
  };

  const categorySelectChangeHandler = (e) => {
    setActiveCategory(e.target.value)
  };

  return (
  <Intl>
    {(t, { getLocaleURL, locale }) => (
    <Root>
      <Wrapper>
        {/* Map: Image Banner */}
        <ImageBanner>
          <img
            src={NeighbourhoodImage}
            alt="Neighbourhood map"
            width="900"
          />
          {
            activeCategory === undefined ||
            data[activeCategory].poi.map((item, index) => (
              <Item
                key={index}
                top={item.top}
                left={item.left}
                active={activeOrder === index}
                onMouseEnter={() => mouseEnterHandler(index)}
                onMouseLeave={mouseLeaveHandler}
              >
                <ItemLabel>{index+1}</ItemLabel>
              </Item> 
          ))}
        </ImageBanner>

        {/* Legends: Text Banner */}
        <TextBanner>

          {/* Tablet and Mobile select dropdown */}
          <Form className="form">
            <label className="label">Select a Category</label>
            <Select
              onChange={categorySelectChangeHandler}
              value={activeCategory}
            >
              {data.map((category, index) => (
              <option key={index} value={index}>
                {t.neighbourhood.map.categories[category.titleId]}
              </option>
            ))}
            </Select>
            <img
              src={require('src/assets/images/icons/select-down.svg')}
              alt="Select"
            />
          </Form>
        
          <TabletMobileActivePoints>
            <CategoryPoints>
              {activeCategory === undefined ? 
                data[0].poi.map((item, index) => (
                  <TextItem
                    key={index}
                    top={item.top}
                    left={item.left}
                    active={activeOrder === index}
                    onMouseEnter={() => mouseEnterHandler(index)}
                    onMouseLeave={mouseLeaveHandler}
                    className="text-item"
                  >
                    <strong>{index+1}</strong> 
                    <TextItemLabel>
                      {item.label}
                      {
                        item.sub &&
                        <SubitemList list={item.sub} />
                      }
                    </TextItemLabel>
                  </TextItem>
                ))
                :
                data[activeCategory].poi.map((item, index) => (
                  <TextItem
                    key={index}
                    top={item.top}
                    left={item.left}
                    active={activeOrder === index}
                    onMouseEnter={() => mouseEnterHandler(index)}
                    onMouseLeave={mouseLeaveHandler}
                    className="text-item"
                  >
                    <strong>{index+1}</strong> 
                    <TextItemLabel>
                      {item.label}
                      {
                        item.sub &&
                        <SubitemList list={item.sub} />
                      }
                    </TextItemLabel>
                  </TextItem>
                ))
              }
            </CategoryPoints>
          </TabletMobileActivePoints>
          {/* End of tablet and mobile dropdown */}

          <TextItemWrapper>
            {/* Desktop category menu */}
            {data.map((category, index) => (
              <Category className="category" key={index}>
                <CategoryTitle className="category-title" onClick={() => categoryClickHandler(index)}>
                  <CloseIcon 
                    showClose={index === activeCategory}
                  />
                  <span>{t.neighbourhood.map.categories[category.titleId]}</span>  
                </CategoryTitle>
                {
                  index === activeCategory &&
                  <CategoryPoints>
                    {category.poi.map((item, index) => (
                      <TextItem
                        key={index}
                        top={item.top}
                        left={item.left}
                        active={activeOrder === index}
                        onMouseEnter={() => mouseEnterHandler(index)}
                        onMouseLeave={mouseLeaveHandler}
                        className="text-item"
                      >
                        <strong>{index+1}</strong> 
                        <TextItemLabel>
                          {item.label}
                          {
                            item.sub &&
                            <SubitemList list={item.sub} />
                          }
                        </TextItemLabel>
                      </TextItem>
                    ))}
                  </CategoryPoints>
                }
              </Category>
            ))}
          </TextItemWrapper>
        </TextBanner>
      </Wrapper>
    </Root>
    )}
    </Intl>
  );
};

const Root = styled.div`
  position: relative;
  ${vw('padding-top', 31, 0, 0)}
`;

const Wrapper = styled.div`
  @media ${media.desktop} {
    display: flex;
  }
`;

const ImageBanner = styled.div`
  position: relative;
  ${vw('width', 320, 708, 900)};
  ${vw('height', 234, 517, 658)};

  margin-left: ${vwMobile(-16)};

  @media ${media.tablet} {
    margin-left: ${vwTablet(-30)};
  }

  @media ${media.desktop} {
    margin-left: 0;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`; 

const TextBanner = styled.div`
  ${vw('padding-top', 15, 30, 0)}
  ${vw('width', 288, 648, 340)}
  margin-left: auto;
  margin-right: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Form = styled.form`
  text-align: left;
  position: relative;
  @media ${media.desktop} {
    display: none;
  }
  img {
    display: block;
    position: absolute; 
    ${vw('width', 12)}
    ${vw('top', 44, 44, 0)}
    ${vw('right', 12, 16, 0)}
  }
`;

const Select = styled.select`
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
  font-family: ${theme.font.family};
  font-style: none;
  width: 100%;
  position: relative;
  appearance: none;
  border-radius: 0;
  background-color: ${({ theme }) => theme.color.blue};
  border: none;
  ${vw('font-size', 18, 21)}
  ${vw('letter-spacing', 1)}
  line-height: 24px;
  transition: ${({ theme }) => theme.transition};
  text-transform: uppercase;
  ${vw('height', 38, 40)}
  ${vw('padding-top', 9, 10)}
  ${vw('padding-right', 12)}
  ${vw('padding-left', 12)}
  ${vw('padding-bottom', 9, 10)}
  ${vw('margin-top', 8, 4)}
  ${vw('margin-bottom', 10)}
  
  &:focus {
    outline: none;
  }
`;

const TextItemWrapper = styled.ul`
  display: none;

  @media ${media.desktop} {
    display: unset;
  }

  .category:first-child .category-title {
    border-top: 1px solid ${theme.color.blue};
  }
`;

const TabletMobileActivePoints = styled.ul`
  @media ${media.desktop} {
    display: none;
  }
`;

const TextItem = styled.li`
  display: flex;
  color: ${theme.color.grey_dark};
  ${vw('font-size', 11, 16)}
  ${vw('line-height', 20)}
  font-weight: bold;
  text-align: left;
  list-style: none;
  cursor: pointer;
  ${vw('padding', 10)}
  border-bottom: 1px solid ${theme.color.blue};

  strong {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    ${vw('font-size', 10, 11)}
    ${vw('width', 15, 20)};
    ${vw('height', 15, 20)};
    ${vw('margin-right', 8, 18)}
    color: white;
    font-weight: bold;
    background-color: #0070B9;
    box-shadow: 0px 2px 10px #444444;
  }

  &:hover {
    background-color: rgba(107, 164, 184, 0.13)
  }
`;

const TextItemLabel = styled.div`
`;

const Item = styled.div`
  ${vw('width', 15, 21)};
  ${vw('height', 15, 21)};
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  background-color: #0070B9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #0070B9;
`;

const ItemLabel = styled.span`
  color: white;
  ${vw('font-size', 10, 11)};
  font-weight: 700;
`;

const Category = styled.div`
  transition: 0.2s all ease;
`;

const CategoryPoints = styled.div`
  border-bottom: 1.5px solid ${theme.color.blue};

  .text-item:last-child {
    border-bottom: none;
  }
`;

const CategoryTitle = styled.h3`
  ${vw('font-size', 21)}
  ${vw('letter-spacing', 1)}
  ${vw('line-height', 20)}
  ${vw('padding-top', 15)}
  ${vw('padding-bottom', 15)}
  ${vw('padding-left', 16)}
  ${vw('margin-bottom', 0)}
  text-transform: uppercase;
  font-weight: bold;
  color: ${theme.color.blue};
  border-bottom: 1px solid ${theme.color.blue};
  cursor: pointer;
  display: flex;
  align-items: center;

  span {
    ${vw('margin-left', 23)}
  }


  &:hover {
    background-color: rgba(107, 164, 184, 0.13)
  }
`;

export default NeighbourhoodMap;
