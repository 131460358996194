import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Intl from 'src/components/Intl';
import theme from 'src/styles/theme';
import media from 'src/styles/media';
import vw from 'src/styles/utils';

import Button from 'src/components/Button';

const Accordion = (props) => {
  const {
    unitId,
    label,
    type,
    sqft,
  } = props;
  return (
     <Intl>
      {(t, { getLocaleURL, locale }) => (
      <Root {...props} to={`${getLocaleURL()}/floorplans/${unitId}`}>
        <Title>
          <PlanId>{label}</PlanId>
          <TitleColumn>
            <PlanType className="plan-type">{type}</PlanType>
            <PlanArea className="plan-area">{sqft} <AreaUnit>aprox. sq ft</AreaUnit></PlanArea>
          </TitleColumn>
        </Title>
      </Root>
      )}
    </Intl>
  );
};

const Root = styled(Link)`
  display: block;
  border-bottom: 1px solid ${theme.color.blue};
`;

const Title = styled.div`
  font-size: 24px;
  display: flex;
  cursor: pointer;
  color: ${theme.color.blue};
  align-items: center;
  justify-content: space-between;
  ${vw('height', 74, 89)}
  transition: 0.2s ease;
  
  &:hover,
  &:active,
  &:focus,
  &.selected {
    color: white;
    background-color: ${theme.color.blue};

    .plan-type,
    .plan-area {
      color: white;
    }
  }
`;

const TitleColumn = styled.div`
  width: 72%;
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

const PlanId = styled.p`
  text-transform: uppercase;
  font-family: ${theme.font.family};
  font-weight: 600;
  ${vw('font-size', 26)}
  ${vw('line-height', 56)}
  ${vw('letter-spacing', 3.2)}
  text-align: center;
  text-transform: uppercase;
  width: 28%;
  
  @media ${media.tablet} {
    text-align: center;
  }
`;

const PlanType = styled.p`
  font-family: ${theme.font.sans_serif_family};
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
  ${vw('font-size', 12, 16)}
  ${vw('line-height', 16, 28)}
  ${vw('letter-spacing', 2, 4)}
  text-align: left;
  color: #4B4E52;

  @media ${media.tablet} {
    flex: 2;
  }
`;

const PlanArea= styled.p`
  font-family: ${theme.font.sans_serif_family};
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
  ${vw('font-size', 12, 16)}
  ${vw('line-height', 16, 28)}
  ${vw('letter-spacing', 2, 4)}
  text-align: left;
  color: #4B4E52;

  @media ${media.tablet} {
    flex: 1;
  }
`;

const PlanMeta = styled.div`
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
  }
`;

const PlanImage = styled.img`
  ${vw('width', 256, 640, 734)}
  height: auto;
`;

const ThumbCompass = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${vw('margin-top', 16, 0)}
  ${vw('margin-bottom', 32, 0)}

  @media ${media.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const AreaUnit = styled.span`
  @media ${media.tablet} {
    display: none;
  }
`;

export default Accordion;
