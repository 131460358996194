import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import MarbleHero from 'src/components/MarbleHero';
import Slick from './Slick';

import data from 'src/data/gallery';

const Gallery = () => {
  return (
  <Intl>
  {(t, { getLocaleURL, locale }) => (
    <Root>
      <MarbleHero
        title={t.gallery.hero.title}
        image={require('src/assets/images/gallery/exterior/gallery_03.jpg')}
        caption={t.gallery.hero.caption}
      />

      <Slick
        title={t.gallery.banner1.title}
        images={data.exterior}
      />

      <Slick
        title={t.gallery.banner2.title}
        images={data.interior}
      />

      <Slick
        title={t.gallery.banner3.title}
        images={data.neighbourhood}
      />
    </Root>
  )}
  </Intl>
  );
};

export default Gallery;

const Root = styled.main``;
