import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from './Intl';
import MarbleBlock from './MarbleBlock';
import PhotoLandscape from './PhotoLandscape';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const MarbleHero = (props) => {
  const { title, subtitle, intro, image, caption, body } = props;

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
      <MarbleBlock {...props}>
        <Root>
          {
            title &&
            <Title>{title}</Title>
          }

          {
            subtitle &&
            <Subtitle className="intro">{subtitle}</Subtitle>
          }

          {
            image &&
            <PhotoLandscape
              src={props.image}
            />
          }

          {
            caption &&
            <Caption className="caption">{caption}</Caption>
          }

          {
            intro &&
            <Wrapper>
              <Intro className="intro">{intro}</Intro>
              <Body>
                {body}
              </Body>
            </Wrapper>
          }

          {
            props.children
          }
        </Root>
      </MarbleBlock>
      )}
    </Intl>
  );
};

MarbleHero.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
  image: PropTypes.string
};

const Root = styled.div`
  text-align: center;
  margin: 0 ${vwMobile(16)};

  @media ${media.tablet} {
    margin-left: ${vwTablet(60)};
    margin-right: ${vwTablet(60)};
  }

  @media ${media.desktop} {
    max-width: ${vwDesktop(1280)};
    margin-right: auto;
    margin-left: auto;
  }
`;

const Title = styled.h1`
  ${vw('margin-bottom', 20, 40, 60)}
`;

const Subtitle = styled.p`
  margin: auto;
  ${vw('margin-top', 20, -22)}
  ${vw('margin-bottom', 40, 60, 80)}
  ${vw('width', 288, 420, 640)};
`;

const Wrapper = styled.div`
  margin-top: ${vwMobile(20)};
  display: flex;
  flex-direction: column;
  text-align: center;
 
  @media ${media.tablet} {
    margin-top: ${vwTablet(60)};
  }

  @media ${media.desktop} {
    margin: ${vwDesktop(80)} 0;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
`;

const Caption = styled.p`
  text-align: left;
`;

const Intro = styled.p`
  text-align: left;
  
  @media ${media.desktop} {
    width: ${vwDesktop(480)};
    margin-left: ${vwDesktop(120)};
  }
`;

const Body = styled.div`
  position: relative;
  margin: auto;
  list-style: none;
  text-align: left;
  margin-top: ${vwMobile(40)};

  @media ${media.tablet} {
    margin-top: ${vwTablet(40)};
    margin-left: 0;
  }

  @media ${media.desktop} {
    width: ${vwDesktop(515)};
    margin-left: ${vwDesktop(176)};
    margin-top: 0;
  }

  p {
    color: #444444;
    ${vw('padding-top', 10, 18)}
    ${vw('padding-bottom', 10, 18)}
    border-bottom: 1px solid ${theme.color.blue};

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }
`;


export default MarbleHero;
