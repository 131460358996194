import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import vw from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

function OwnerBanner(props) {
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root>
          <Article>
            <h1>{props.title}</h1>
            <Image src={props.image} className="show-on-mobile"></Image>
            <p className="body">
              {props.body}
            </p>
          </Article>
          <Image src={props.image} className="hide-on-mobile"></Image>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  background-color: ${theme.color.cc_off_white_bg};
  ${vw('padding-left', 30, 60, 0)};
  ${vw('padding-bottom', 64, 120, 0)}; 
  @media ${media.desktop} {
    display: flex;
    background-color: ${theme.color.cc_off_white_bg}
  }

  .hide-on-mobile {
    display: none;

    @media (min-width: 769px) {
      display: unset;
    }
  }

  .show-on-mobile {
    display: unset;

    @media (min-width: 769px) {
      display: none;
    }
  }

  h1 {
    ${vw('margin-bottom', 20, 40, 0)}
  }
`;

const Image = styled.img`
  ${vw('width', 290, 710, 1020)}
  ${vw('height', 212, 520, 750)}
  position: relative;
  z-index: 1;
`;

const Article = styled.div`
  ${vw('padding-top', 52, 88, 120)}
  ${vw('padding-right', 0, 0, 60)}
  ${vw('padding-left', 0, 0, 60)}

  p {
    ${vw('width', 256, 531, 300)}
    ${vw('letter-spacing', 1, 3)}
  }
`;

export default OwnerBanner;
