import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import MarbleBlock from 'components/MarbleBlock';
import Intro from './Intro';

import Tagline from '../elements/Tagline';
import TaglineMobile from '../elements/TaglineMobile';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = () => {
  const [start, setStart] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setStart(true), 2000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Intl>
      {(t, { locale }) => (
        <MarbleBlock>
          <Container>
            {locale === 'en' ? (
              <>
                <Tagline />
                <TaglineMobile />
              </>
            ) : (
              <h1 className="localized" style={{ opacity: start ? 1 : 0 }}>
                {t.home.hero.script}
              </h1>
            )}
          </Container>
          <Intro />
        </MarbleBlock>
      )}
    </Intl>
  );
};


const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
  .localized {
    transition: ${({ theme }) => theme.transition};
    transition-delay: 0.5s;
    ${vw('margin-left', 16, 60, 0)}
    ${vw('margin-right', 16, 60, 0)}
    @media ${media.desktop} {
      br {
        display: none;
      }
    }
  }
`;
export default Hero;
