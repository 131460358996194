// Packages
import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import Intl from 'src/components/Intl';
import Spotlight from './Spotlight';

// Data
import data from 'src/data/amenities';

// Asset
import HotSpotIcon from 'src/assets/images/amenities/birdeyes/hotspot.png';

// Styles
import vw from 'src/styles/utils';
import media from 'src/styles/media';

const ToggleMap = () => {
  const [activeLevel, setActiveLevel] = useState(1);
  const [showSpotlight, setShowSpotlight] = useState(false);
  const [spotlightImage, setSpotlightImage] = useState('');
  const [spotlightTitleId, setSpotlightTitleId] = useState('');
  
  const levelClickHandler = (e, index) => {
    e.preventDefault();
    setActiveLevel(index);
  };

  const hotSpotClickHandler = (data) => {
    setShowSpotlight(true);
    setSpotlightImage(data.image)
    setSpotlightTitleId(data.titleId)
  }

  const closeSpotlight = () => 
    setShowSpotlight(false);
  
  return (
    <Intl>
      {(t, { locale, getLocaleURL }) => {
        return (
          <Root>
            {
              showSpotlight &&
              <Spotlight
                closeHandler={closeSpotlight}
                image={spotlightImage}
                title={t.amenities[spotlightTitleId]}
              />
            }
            <ToggleBar>
            {
              data.map((level, index) => (
                <Level
                  className="level"
                  key={level.title}
                  active={index === activeLevel}
                  href="#"
                  onClick={() => levelClickHandler(event, index)}
                >
                  {t.amenities[level.titleId]}
                </Level>
              ))
            }
            </ToggleBar>

            <BirdeyeContainer>
              <Birdeye src={data[activeLevel].levelImage} />
              {
                data[activeLevel].hotSpots.map((hotSpot) => (
                  <HotSpot
                    onClick={() => hotSpotClickHandler(hotSpot)}
                    src={HotSpotIcon}
                    alt="call out"
                    positions={hotSpot.positions}
                  />
                ))
              }
            </BirdeyeContainer>
          </Root>
        );
      }}
    </Intl>
  );
};

export default ToggleMap;

const Root = styled.div`
  text-align: center;
  ${vw('padding-bottom', 60, 80, 120)}

  display: none;

  @media ${media.tablet} {
    display: block;
  }
`;

const ToggleBar = styled.div`
  border: 1px solid #6BA4B8;
  border-radius: 60px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  ${vw('margin-bottom', 40)};

  ${vw('width', 624)}
  ${vw('height', 44)}

  .level:first-child {
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
    border-right: 1px solid #6BA4B8;
  }

  .level:last-child {
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
    border-left: 1px solid #6BA4B8;
  }
`;

const Level = styled.a`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${vw('font-size', 13)}
  ${vw('letter-spacing', 1)}
  text-transform: uppercase;
  position: relative;
  cursor: pointer;

  // Active conditional state
  background-color: ${(props) => props.active ? '#6BA4B8' : '#fff'};
  color: ${(props) => props.active ? '#fff' : '#6BA4B8'};

  ${(props) => props.active && `
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      width: 0;
      height: 0;
      margin: auto;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid #6BA4B8;
      clear: both;
    }
  `}

  ${(props) => !props.active && `
    &:hover {
      background-color: rgba(107, 164, 184, 0.1);
    }
  `}
`;

const BirdeyeContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: auto;
`;

const Birdeye = styled.img`
  margin: auto;
  width: auto;
  ${vw('height', 207, 504, 600)};
`;

const HotSpot = styled.img`
  background-image: url(${HotSpotIcon});
  position: absolute;
  ${vw('width', 50, 70, 90)};
  ${vw('height', 50, 70, 90)};
  top: ${props => `${props.positions[1]/1440 * 100}%`};
  left: ${props => `${props.positions[0]/1980 * 100}%`};
  cursor: pointer;
`;
