// Packages
import React from 'react';
import styled from 'styled-components';

// Components
import Intl from 'src/components/Intl';
import MarbleHero from 'src/components/MarbleHero';
import NeighbourhoodMap from './NeighbourhoodMap';
import ContentBlock from 'src/components/ContentBlock';
import CenterBlock from 'src/components/CenterBlock';
import ScriptBanner from 'src/components/ScriptBanner';

// Styles
import vw from 'src/styles/utils';

const Neighbourhood = () => {
  return (
    <Intl>
    {(t, { getLocaleURL, locale }) => (
      <Root>
        {/* Hero > Map */}
        <MarbleHero
          title={t.neighbourhood.hero.title} 
          subtitle={t.neighbourhood.hero.subtitle} 
        >
          <NeighbourhoodMap />
        </MarbleHero>

        {/* Script */}
        <ScriptBanner 
          image={require('src/assets/images/neighbourhood/script.svg')}
          localeText={t.neighbourhood.script}
        />
        
        {/* Banner Image Right */}
        <ContentBlock
          intro={t.neighbourhood.banner1.text}
          image={require('src/assets/images/neighbourhood/restaurant.jpg')}
          reverse
        />

        {/* Banner Image Left */}
        <ContentBlock
          intro={t.neighbourhood.banner2.text}
          video="https://player.vimeo.com/progressive_redirect/playback/671283944/rendition/540p/540p.mp4?loc=external&signature=c18412f5c27a719fc49f285116dd34fcfe7197e88dc2176489306be0b91cf40a"
        />
        
        {/* Centre Banner */}
        <CenterBlock
          intro={t.neighbourhood.banner3.text}
        />
        
      </Root>
      )}
    </Intl>
  );
};

const Root = styled.main`
  ${vw('padding-bottom', 20, 40)}
`;

export default Neighbourhood;
