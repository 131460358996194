import React from 'react';
import styled from 'styled-components';

import TreeRings from 'src/assets/images/elements/tree_rings_1.svg';

function TreeRingsA(props) {
  return (
    <Root src={TreeRings} {...props}></Root>
  );
};

const Root = styled.img`
  z-index: 0;
`;

export default TreeRingsA;
