export default [
  {
    title: 'Shopping',
    titleId: 'shopping',
    poi: [
      {
        label: 'The City of Lougheed',
        top: '65.8%',
        left: '35%',
        sub: [
          'Sport Chek',
          'Walmart',
          'Hudson’s Bay',
          'Fitness World',
          'London Drugs',
        ],
      },
      {
        label: 'Northgate Village',
        top: '61.2%',
        left: '36.4%',
        sub: ['Save-On Foods', 'Shoppers Drug Mart', 'BC Liquor Store'],
      },
      {
        label: 'Burquitlam Plaza',
        top: '44.5%',
        left: '44.2%',
      },
      {
        label: 'Cariboo Centre',
        top: '70.6%',
        left: '40.4%',
      },
      {
        label: 'Hannam Supermarket',
        top: '75.1%',
        left: '36.8%',
      },
      {
        label: 'Hanin Village',
        top: '79.5%',
        left: '40.1%',
      },
    ],
  },
  {
    title: 'Parks & Recreation',
    titleId: 'parks',
    poi: [
      {
        label: 'Burquitlam Park',
        top: '44.1%',
        left: '45.2%',
      },
      {
        label: 'The Vancouver Golf Club',
        top: '64.4%',
        left: '48.0%',
      },
      {
        label: 'Blue Mountain Park',
        left: '62.7%',
        top: '61.7%',
      },
      {
        label: 'Burnaby Mountain Conservation Area',
        top: '23.8%',
        left: '23.3%',
      },
      {
        label: 'Burnaby Lake Regional Park',
        top: '76.2%',
        left: '11.8%',
      },
      {
        label: 'Hume Park',
        top: '93%',
        left: '40.1%',
      },
      {
        label: 'Como Lake Park',
        top: '47.3%',
        left: '69%',
      },
      {
        label: 'Poirier Sport and Leisure Complex',
        top: '56.3%',
        left: '81.9%',
      },
      {
        label: 'Mundy Park',
        top: '53.2%',
        left: '93.5%',
      },
      {
        label: 'Rocky Point Park',
        top: '6.2%',
        left: '86%',
      },
    ],
  },
  {
    title: 'Restaurants',
    titleId: 'restaurants',
    poi: [
      {
        label: 'Sushi California',
        top: '65.8%',
        left: '40.2%',
      },
      {
        label: 'Green Leaf Café',
        top: '61.1%',
        left: '34.3%',
      },
      {
        label: 'Rosa’s Cucina Italiana',
        top: '10.1%',
        left: '64.3%',
      },
      {
        label: 'Cactus Club Café',
        top: '93%',
        left: '77%',
      },
      {
        label: 'The Taphouse',
        top: '72.7%',
        left: '40%',
      },
    ],
  },
  {
    title: 'Schools',
    titleId: 'schools',
    poi: [
      {
        label: 'Roy Stibbs Elementary',
        top: '56.8%',
        left: '46.8%',
      },
      {
        label: 'École Glenayre Elementary',
        top: '18%',
        left: '39.8%',
      },
      {
        label: 'École Banting Middle School',
        top: '35.6%',
        left: '54.4%',
      },
      {
        label: 'Port Moody Secondary',
        top: '19.4%',
        left: '60.2%',
      },
      {
        label: 'École Dr Charles Best Secondary',
        top: '38.5%',
        left: '95%',
      },
    ],
  },
  {
    title: 'Ledmac Projects',
    titleId: 'projects',
    poi: [
      {
        label: 'Highpoint (Under Construction)',
        top: '47.7%',
        left: '41.2%',
      },
      {
        label: 'Sydney',
        top: '70.3%',
        left: '42.9%',
      },
      {
        label: 'Northgate Village & Silhouette',
        top: '61.4%',
        left: '36.5%',
      },
      {
        label: 'Strathmore Lane',
        top: '56%',
        left: '35.5%',
      },
      {
        label: 'Stonebrook',
        top: '55.6%',
        left: '29.6%',
      },
      {
        label: 'Cameron',
        top: '59.2%',
        left: '26.3%',
      },
      {
        label: 'Charland',
        top: '70.3%',
        left: '60.6%',
      },
    ],
  },
];
