import React from 'react';
import Page from 'src/components/Page';

import Intl from 'src/components/Intl';

const ThankYou = () => {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Page
          text={t.thankYou.text}
          header={require('src/assets/images/text/heading-thank-you.svg')}
          headerText={t.thankYou.title}
          headerWidth={398}
          localized
        />
      )}
    </Intl>
  );
};

export default ThankYou;
