export default {
  color: {
    blue: '#6BA4B8',
    grey_dark: '#444444',
    grey_medium: '#888888',
    grey_light: '#BBBBBB'
  },
  font: {
    family: 'Montserrat'
  },
  transition: '0.4s ease',
};
