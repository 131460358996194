import React from 'react';

import Page from 'src/components/Page';
import Intl from 'src/components/Intl';
import MarbleHero from 'src/components/MarbleHero';

const NotFound = () => {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Page
          text={t.notFound.text}
          header={require('src/assets/images/text/heading-page-not-found.svg')}
          headerText="Page Not Found"
          headerWidth={566}
        />
      )}
    </Intl>
  );
};

export default NotFound;
