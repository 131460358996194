import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from 'src/components/Intl';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ScriptBanner = (props) => {
  const { image, localeText } = props; 

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
        {
          locale === 'en' ?
          <Script
            src={image}
          />
          :
          <h1>{localeText}</h1>
        }
        </Root>
      )}
    </Intl>
  );
};

ScriptBanner.propTypes = {
  image: PropTypes.element,
  localeText: PropTypes.string
};

const Root = styled.div`
  text-align: center;
  ${vw('padding-top', 60, 80, 160)}
  ${vw('padding-bottom', 20, 60)}
`;

const Script = styled.img`
  width: ${vwMobile(288)};
  height: auto;


  @media ${media.tablet} {
    width: auto;
    height: ${vwTablet(120)};
  }

  @media ${media.desktop} {
    height: ${vwDesktop(120)};
  }
`;

export default ScriptBanner;
