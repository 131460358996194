import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useParams, useHistory } from 'react-router-dom';
import converter from 'number-to-words';

import Intl from 'src/components/Intl';
import Button from 'src/components/Button';
import MarbleBlock from 'src/components/MarbleBlock';

import floorplans from 'src/data/floorplans';
import { path } from 'src/config/app.conf';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

const SinglePlan = () => {
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    // i dont know why, but a scroll-lock is being applied to this page when refreshed.
    document.body.style.overflow = '';
  }, []);

  const data = floorplans.filter(
    (plan) => plan.id.toLowerCase() === params.id.toLowerCase()
  )[0];

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
      <Root className="marble-hero">
        <Close to={`${getLocaleURL()}/floorplans`}>
          {t.floorplans.back}
        </Close>

        <Flex>
          <Left>
            <Info>
              <Title>{data.label}</Title>
              {
                data.type &&
                <Type>{data.type}</Type>
              }

              <p className="bed">{ locale == 'en' ? converter.toWords(data.bed) : data.bed } {t.floorplans.bed} {data.den ? ` + ${t.floorplans.den}` : ''}</p>
              <p className="bath">{ locale == 'en' ? converter.toWords(data.bath) : data.bath } {t.floorplans.bath}</p>

              <Area>
                <p className="indoor">
                  <strong>{t.floorplans.indoor}: </strong>
                  {t.floorplans.approx} {data.indoor} sq.ft.
                </p>
                {data.outdoor && (
                  <p className="outdoor">
                    <strong>{t.floorplans.outdoor}: </strong>
                    {t.floorplans.approx} {data.outdoor} sq.ft.
                  </p>
                )}
                <p>
                  <strong>{t.floorplans.total}: </strong>
                  {t.floorplans.approx} {data.total} sq.ft.
                </p>
              </Area>
            </Info>

            <PlateImage
              src={require(`src/assets/images/floorplans/png/floorplates_thumbs/${data.id}.png`)}
              alt="Plate image"
              width="150"
            />

            <DownloadButton
              href={`/plans/Precidia_${data.label.toUpperCase()}.pdf`}
              className="button"
              target="_blank"
            >
              {t.floorplans.downloadPlan}
            </DownloadButton>
          </Left>

          <Right>
            <PlanImage
              src={require(`src/assets/images/floorplans/png/${data.id}.png`)}
              alt="Plan image"
              width="875"
            />
          </Right>
        </Flex>
    </Root>
    )}
    </Intl>
  );
};

const Root = styled(MarbleBlock)`
  text-align: left;
  background-color: ${({ theme }) => theme.color.background};
  position: relative;
  padding-left: ${vwMobile(18)};
  padding-right: ${vwMobile(18)};
  padding-bottom: ${vwMobile(100)};

  @media ${media.tablet} {
    padding-left: ${vwTablet(60)};
    padding-right: ${vwTablet(60)};
    padding-bottom: ${vwTablet(140)};
  }

  @media ${media.desktop} {
    padding-left: ${vwDesktop(120)};
    padding-right: ${vwDesktop(80)};
    padding-top: ${vwDesktop(230)};
  }
`;

const Flex = styled.div`

  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const Left = styled.div`
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  ${vw('margin-bottom', 60, 40, 0)};

  @media ${media.tablet} {
    display: flex;
  }

  @media ${media.desktop} {
    width: ${vwDesktop(300)};
    display: block;
  }

  p {
    text-transform: uppercase;
    font-weight: 300;
    line-height: 26px;
    font-size: 13px;
    letter-spacing: 0.5px;
  }
`;

const Info = styled.div`
  @media ${media.tablet} {
    width: ${vwTablet(300)};
  }

  @media ${media.desktop} {
    width: 100%;
  }
`;

const Right = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  ${vw('font-size', 28, 38, 96)};
  ${vw('letter-spacing', 3.2)};
  color: ${theme.color.blue};
  line-height: inherit;
  text-align: left;
  font-weight: 600;
`;

const Type = styled.strong`
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  ${vw('margin-bottom', 20)}
  ${vw('margin-top', 0, 0, -10)}
  ${vw('letter-spacing', 0.5, 1)}
  ${vw('font-size', 12, 16)}
  color: ${theme.color.blue};
`;

const PlanImage = styled.img`
  ${vw('width', 288, 482, 856)};
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: inline-block;
`;

const PlateImage = styled.img`
  ${vw('width', 197)};
  height: auto;
`;

const Close = styled(Link)`
  ${vw('margin-bottom', 40, 60, 20)};
  display: block;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 1px;
  color: ${theme.color.grey_dark};
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
  text-align: left;
  ${vw('font-size', 13)}

  @media ${media.desktop} {
    width: unset;
    position: unset;
  }
`;

const Area = styled.div`
  border-top: 1px solid ${theme.color.blue};
  border-bottom: 1px solid ${theme.color.blue};
  ${vw('padding-top', 8)};
  ${vw('padding-bottom', 8)};
  ${vw('margin-top', 7)};
  ${vw('margin-bottom', 17)};
`;

const DownloadButton = styled.a`
  display: inline-block;
  ${vw('margin-top', 40)};
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  ${vw('bottom', 50, 60, 0)};
  ${vw('width', 150, 210)};

  @media ${media.desktop} {
    position: relative;
  }
`;

export default SinglePlan;
