import React from 'react';
import styled from 'styled-components';

import theme from 'src/styles/theme';

const CloseIcon = (props) => {
  const {showClose} = props;

  return (
    <Root showClose={showClose} width="9px" height="9px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-2440.000000, -583.000000)" fill="#47889E" fill-rule="nonzero">
          <g transform="translate(2440.000000, 583.000000)">
              <polygon points="16.1752577 18 18 16.1752577 10.8247423 9 18 1.82474227 16.1752577 0 9 7.17525773 1.82474227 0 0 1.82474227 7.17525773 9 0 16.1752577 1.82474227 18 9 10.8247423"></polygon>
          </g>
        </g>
      </g>
    </Root>
  );
};

const Root = styled.svg`
  stroke: ${(props) => props.showClose ? theme.color.blue : theme.color.cc_grey};
  transform: ${(props) => props.showClose ? 'rotate(0)' : 'rotate(45deg)'};
  transition: 0.2s all ease;
`;

export default CloseIcon;
