import React from 'react';

export default {
  pages: {
    floorplans: '플로우플랜',
    interiors: '인테리어',
    amenities: '편의시설',
    neighbourhood: '주거환경',
    gallery: '갤러리',
    developer: '개발회사',
    register: <>지금 등록하세요!</>,
  },
  bookAppointment: '지금 사전 예약하세요',
  backButton: 'Back to Precidia',
  home: {
    hero: {
      script: '당신의 삶을 향상시키세요.',
      title:
        '29층으로 우뚝 솟은 Precidia는 1 베드룸 + 덴에서부터 3베드룸의 178 세대로 구성된 부티크 컬렉션으로 West Coquitlam의 중심부에 건설됩니다.',
      text: '평온한 생활과 도시의 편리함이 교차하는 곳에 들어서는  Precidia는 조용한 주거 지역인 Cottonwood Avenue와 Whiting Way에 위치하여 일상에 필요한 모든 것들이 연결되어 있어 평화롭고 조화로운 삶을 영위하기에 최적의 주거단지입니다.',
      button: '플로우플랜 보기',
    },
    content1: {
      title: '당신만을 위한 보금자리',
      text: '세련된 기능부터 시간이 흘러도 변치 않는 각종 비품에 이르기까지 Ledingham McAllister의 주택은 언제나 모든 세대에 품질과 가치를 제공하는 것으로 유명합니다.',
      button: '인테리어',
    },
    content2: {
      title: '모든 세부 사항을 생각한 꼼꼼한 설계',
      text: '웅장한 현관에 도착하는 순간부터 사려 깊게 선별된 편의 시설 공간에 이르기까지 모든 입주자들에게 더 많은 것을 제공하기 위해 끝없는 노력을 기울이고 있습니다.',
      button: '편의시설들',
    },
    content3: {
      title: '조용하지만 모든 것이 연결된 편리함',
      text: 'Precidia는 조용하지만 모든 것이 연결된 주거 지역에 위치하여 일상에 필요한 모든 것들이 연결되어 있어 평화롭고 조화로운 삶을 영위하기에 최적의 주거단지입니다. 스카이트레인, 쇼핑, 레스토랑 등이 가까이 위치하고 있어 Precidia는 일은 물론 웰빙 및 삶에 필요한 모든 것을 위한 완벽한 주거단지입니다.',
      button: '주거지역',
    },
    backHome: 'Back to homepage',
  },
  floorplans: {
    title: (
      <>
        1 베드룸 & 덴에서 3 베드룸 주택으로 <br />
        이루어진 178세대의 친밀한 주거단지
      </>
    ),
    header: {
      plan: '플랜',
      type: '타입',
      sqft: '면적',
    },
    types: {
      oneBedOneDen: '1베드룸 & 덴',
      twoBed: '2 베드룸',
      threeBed: '3 베드룸',
    },
    downloadPlan: 'Download Plan',
    bed: '베드룸',
    den: '덴',
    bath: 'bath',
    indoor: '실내 생활',
    outdoor: '실외 생활',
    approx: '약',
    total: '합계',
    back: '<  BACK TO ALL FLOORPLANS',
  },
  interiors: {
    hero: {
      title: '당신만을 위한 보금자리',
      intro:
        'Precidia에서 언제나 품격 있는 생활을 즐길 수 있습니다. 모든 세부 사항을 염두에 두고 꼼꼼하게 설계된 1베드룸 & 덴에서 3 베드룸으로 구성된 부티크 컬렉션입니다. Precidia의 모든 세대는 높은 천장과 노스 쇼어 산맥에서 Fraser River까지의 그림 같은 전망을 제공하는 넓은 창문이 있는 개방형 컨셉트의플로우 플랜을 자랑합니다.',
      body: (
        <>
          <p className="body">9' 천장과 집안 전체에 걸친 에어컨디셔닝</p>
          <p className="body">
            석영 카운터 탑과 현대적 감각의 타일 백스플래시가 있는 모던한 주방
          </p>
          <p className="body">
            가스 쿡탑을 포함한 풀 사이즈 KitchenzAid 스테인리스 스틸 가전
            제품으로 즐기는 요리와 각종 엔터테인먼트
          </p>
          <p className="body">
            풀 사이즈 프론트 로딩 방식의 에너지 효율 월풀 세탁기 및 건조기
          </p>
          <p className="body">
            석영 카운터 탑과 세련된 캐비닛 아래 조명을 갖춘 멋진 플로팅 세면대가
            있는 스파 분위기의 욕실
          </p>
          <p className="body">
            원활한 야외 생활을 위한 지붕이 있는 넓은 발코니
          </p>
        </>
      ),
    },
    banner: {
      title: '시대를 초월한 마감',
      body: '끊임없이 변화하는 삶과 스타일에 맞춰 세심하게 선별된 두 가지의 디자이너 색채 조합이 제공됩니다. 밝고 모던한 Chestnut 색상, 혹은 보다 현대적이고 담백한 뉴트럴 Ivory 색상 중에서 선택하십시오.',
      drag: 'drag',
      dark: 'dark scheme: chestnut',
      light: 'light scheme: ivory',
    },
    banner2: {
      text: '세련된 기능부터 시간이 흘러도 변치 않는 각종 설비에 이르기까지 Ledingham McAllister 주택은 언제나 모든 세대에 품질과 가치를 고취시키는 것으로 유명합니다.',
      buttonLabel: '주요 특징 안내서 다운로드',
    },
  },
  amenities: {
    hero: {
      title: '모든 세부 사항을 염두에 둔 꼼꼼한 설계',
      intro:
        '수상 경력에 빛나는 IBI 그룹과 협력하여 Precidia는 웅장한 분위기의 현관에 도착하는 순간부터 건물 전체에 걸쳐 세심하게 선별된 편의 시설 공간에 이르기까지 모든 세부 사항을 염두에 두고 아주 꼼꼼하게 설계되었습니다.',
      body: (
        <>
          <p className="body">수상 경력에 빛나는 IBI Group의 아키텍처</p>
          <p className="body">
            화려한 인공폭포가 맞이하는 장엄한 현관과 편리한 자동차 코트
          </p>
          <p className="body">
            호화로운 벽난로 라운지가 있는 드라마틱하게 높은 천장의 로비
          </p>
          <p className="body">언제나 친절한 전용 컨시어지 서비스</p>
          <p className="body">철저한 보안 방문자 주차</p>
          <p className="body">외부에서 방문한 손님들을 위한 게스트 스위트</p>
        </>
      ),
    },
    banner1: {
      title: '라이프 스타일을 업그레이드하세요.',
      text: 'Precidia는 10,000 sq. ft.의 완전히 프로그램 되어 있는 입주자 전용 편의 시설로 라이프스타일을 한 단계 업그레이드시켜 드립니니다. 엘리베이터를 타고 조금만 이동하면 인상적인 입주자 전용 편의 시설들과 가구가 완비된 게스트 스위트를 통해 각종 모임을 주관할 수 있으며 엔터테인먼트(심지어 야간 방문객도 포함)를 위해 설계된 장소를 마음껏 이용할 수 있습니다.',
    },
    banner2: {
      title: '머물 수 있는데 왜 떠나려고 하는가?',
      body: (
        <>
          <p className="body">
            벽난로 옆 라운지, 당구대, 완벽하게 갖춰진 주방 및 분리되어 분위기가
            있는 식당과 따뜻하고 매력적인 사교를 위한 중심지
          </p>
          <p className="body">
            난로 옆 라운지, 식당, 어린이 놀이 공간이 있는 옥상 테라스
          </p>
          <p className="body">완벽하게 갖춰진 피트니스 센터</p>
          <p className="body">
            택배, 자전거의 보관 및 수리 공간을 갖춘 다목적실
          </p>
          <p className="body">애완견 목욕실</p>
        </>
      ),
    },
    banner3: {
      title: '고품격 생활을 위해 집으로 오세요',
    },
    ground: '지하층',
    lobby: '로비층',
    amenity: '편의시설층',
    lobbyEntrance: 'Lobby Entrance',
    rooftop: 'Amenity Level Rooftop',
    autoCourt: 'Auto Court Entrance',
    grandLobbyEntrance: 'Grand Lobby Entrance',
    concierge: 'Concierge',
    fitnessCentre: 'Fitness Centre',
    kitchen: 'Chef’s Kitchen',
    billards: 'Billards',
    outdoorLounge: 'Outdoor Lounge',
  },
  neighbourhood: {
    hero: {
      title: '조용하지만 모든 것이 연결됨',
      subtitle:
        'West Coquitlam의 심장부에 있는 Cottonwood Avenue와 Whiting Way의 주거 거리에 위치한 Precidia는 모든 욕구를 만족시키기에 편리한 위치에 있습니다.',
    },
    script: '매혹적인 뒷마당',
    banner1: {
      text: '스카이트레인, 쇼핑, 레스토랑 등이 가까이에 위치하고 있는 Precidia는 업무, 웰빙 및 그 외의 생활에 필요한 모든 것을 위한 완벽한 장소입니다.',
    },
    banner2: {
      text: '열정적인 사람이든 혹은 평온함을 추구하는 사람이든 누구에게나 Precidia는 삶의 모든 순간을 위한 완벽한 환경을 제공해 줍니다.',
    },
    banner3: {
      text: '새로운 삶에 도전하는 것을 즐기는 사람들이 가까운 곳과 먼 곳에서부터 West Coquitlam의 매혹적인 뒷마당을 즐기기 위해 여행하는 이유가 있습니다.',
    },
    map: {
      categories: {
        shopping: '쇼핑',
        parks: '공원 & 레크레이션',
        restaurants: '음식점 & 카페들',
        schools: '교육 시설',
        projects: 'Ledmac 프로젝트',
      },
    },
  },
  gallery: {
    hero: {
      title: (
        <>
          Ledingham McAllister의 West Coquitlam을 <br />
          위한 다음 비전
        </>
      ),
      caption: 'auto court',
    },
    banner1: {
      title: (
        <>
          비교할 수 없는 <br />
          생활 경험
        </>
      ),
    },
    banner2: {
      title: (
        <>
          Precidia의 품격 있는 <br className="hide-on-mobile" />
          생활을 즐겨 보세요.
        </>
      ),
    },
    banner3: {
      title: (
        <>
          Precidia는 일, 웰빙 및 그 <br className="hide-on-mobile" />
          외의 모든 것들을 위한 완벽한 <br className="hide-on-mobile" />
          주거단지입니다.
        </>
      ),
    },
  },
  developer: {
    hero: {
      title: '신뢰, 품질, 가치',
      body: 'Ledingham Mcallister는  최적의 위치에 탁월한 새로운 주거 커뮤니티를 조성함으로써 미래지향적이고 스마트한 개발에 전념하고 있습니다. 한 세기가 넘는 기간 동안 우리는 품질과 가치가 지속되는 주택을 보장하기 위해 엄격한 설계, 환경 리더십, 엔지니어링 및 건설 표준에 전념해 왔습니다. 15,000세대가 넘는 주택 건설 이후에 우리의 과거가 그러한 실적 위에 건설되었으며 우리의 미래가 그것에 의해 펼쳐질 것이라고 우리는 자랑스럽게 말할 수 있습니다.',
    },
    companies: {
      ibi: {
        description:
          'IBI Group 은 이제 자랑스럽게 Arcadis의 일부가 되었습니다. Arcadis Group 은 약 50년 동안 고객에게 건축, 공학, 계획, 시스템 및 다양한 기술 서비스를 제공하는 기술 중심적 설계 회사입니다. 전세계를 통틀어 총 60개 이상의 사무실과 2,700명 이상의 전문가를 거느린 이 회사는 지능적인 관행과 보다 확립된 역량을 핵심 빌딩 및 인프라 비즈니스로 이끌어냄으로써 대응력이 강하고 탄력적인 공간과 스마트한 도시 환경을 만듭니다. 고층빌딩에서 병원, 대중 교통 시스템, 학교에 이르기까지 Arcadis Group은 사람들이 미래의 도시에서 생활하고, 일하고, 움직이고, 배우고, 치유하는 방식을 건설하고자 노력합니다.',
      },
      pwl: {
        description:
          '40년 동안 PWL Partnership의 조경 건축가, 도시 디자이너 및 설계자 팀은 캐나다, 미국 및 아시아 전역에서 우수한 명성을 쌓아왔습니다. 그들은 5명의 파트너, 4명의 어소시에이트, 30명의 조경 건축가 및 디자이너로 구성된 팀이 있습니다. 밴쿠버에 기반을 둔 PWL은 고객 그룹, 프로젝트 팀 및 지역 내 관계자와 의미 있고 지속적인 관계를 구축하기 위해 부단히 노력하고 있습니다. PWL의 경험은 해안가 복합단지, 녹지, 대중 교통 환승통로, 도시 광장, 거리 경관, 근린 공원 및 공원을 총괄합니다.',
      },
      themill: {
        description:
          'The Mill Design Group Inc.는 2011년 인테리어 디자이너 Janine Wilson에 의해 설립되었습니다. 아름다운 노스 밴쿠버에 기반을 둔 Mill Design Group Inc.는 다세대 주택 디자인에 매우 특화된 회사입니다. 인테리어 디자인 업계에서 18년 이상의 경험을 가진 Janine과 그녀의 놀라운 팀은 개발자들과 협력하여 미래 구매자의 미학과 가치를 구현하는 동시에 주거단지의 장점을 반영하는 인테리어 및 주택 마감을 창출해 냅니다. Mill의 디자인 신념은 우아함과 편안함을 희생하지 않은 인테리어 마무리가 시대를 초월하고 관리하기 쉬워야 한다는 것입니다.',
      },
    },
  },
  footer: {
    column1: {
      title: '연락하기',
      buttonLabel: '지금 등록하세요!',
    },
    column2: {
      title: '프레젠테이션 센터',
      text: (
        <>
          매일 오후 12 시 – 오후 5시 오픈 <br />
          (금요일은 휴무)
          <br />
          #150 – 3355 North Road, Burnaby, BC
        </>
      ),
      buttonLabel: '지금 사전 예약하세요',
    },
    column3: {
      title: '현장 위치',
    },
    marketing: 'marketing in association with',
    disclaimer:
      '*Limited time, prices, incentives, and commissions are subject to change without notice. The 3% cash back will be shown as credit on statement of adjustments upon completion. Ask a sales representative for more details. The information contained in this promotional material is for illustration purposes only and is subject to change without notice. Maps and renderings are an artistic representation only. The quality residences at Precidia are developed by LM Precidia Homes Limited Partnership. The developer reserves the right to make modifications or substitutions should they be necessary. This is currently not an offering for sale. Any such offering can only be made by way of a disclosure statement. E. & O.E.',
    corporate: {
      title: '믿음. 품질. 가치',
      text: 'LEDINGHAM MCALLISTER는 좋은 위치에 우수한 새로운 주거 커뮤니티를 조성하는 우아하고 세련된 주택 개발에 전념하고 있습니다. 지난 한 세기 동안 오래도록 지속되는 품질과 가치를 보장하는 주택 건설을 위해 설계는 물론 환경 리더십, 엔지니어링 및 엄격한 건설 표준 준수 등 최선을 다하겠다는 스스로의 약속을 철저히 지켜 왔습니다. 향후에 18,000세대 이상의 주택이 건설되고 나면 우리는 우리의 과거가 그 위에 건설되었으며 우리의 미래 역시 그것에 의해 만들어 질 것이라고 자랑스럽게 말할 수 있을 것입니다.',
      privacy: '개인정보 정책',
    },
  },
  register: {
    title: '지금 등록하세요.',
    firstname: '이름*',
    lastname: '성*',
    email: '이메일 주소*',
    phone: '전화번호*',
    zip: '우편번호*',
    select: '하나만 선택해 주세요.',
    buyRent: '구매하시거나 임대하시나요? *',
    buyRentOptions: [
      {
        label: '구매',
        value: 'Buy',
      },
      {
        label: '임대',
        value: 'Rent',
      },
    ],
    howHeard: 'Precidia에 대해 어떻게 알게 되었나요? *',
    howHeardOptions: [
      {
        label: '온라인 광고',
        value: 'Online Ads',
      },
      {
        label: '중국어 광고',
        value: 'Chinese Ads',
      },
      {
        label: '한국어 광고',
        value: 'Korean Ads',
      },
      {
        label: '인쇄 광고',
        value: 'Print Advertising',
      },
      {
        label: '소셜 미디어',
        value: 'Social Media',
      },
      {
        label: '간판',
        value: 'Signage',
      },
      {
        label: '리얼터',
        value: 'Realtor',
      },
      {
        label: '친구 & 가족',
        value: 'Friend & Family',
      },
      {
        label: '기타',
        value: 'Other',
      },
    ],
    whatInquire: '궁금하신 사항은 무엇이신가요?',
    whatInquireOptions: [
      {
        label: '1 베드룸',
        value: 'One bedroom',
      },
      {
        label: '2 베드룸',
        value: 'Two bedroom',
      },
      {
        label: '3 베드룸',
        value: 'Three bedroom',
      },
      {
        label: '펜트 하우스',
        value: 'Penthouses',
      },
    ],
    realtor: '리얼터이신가요?*',
    workingWithRealtor: '리얼터와 함께 일하시나요?',
    yes: '예',
    no: '아니오',
    ifYesRealtor: `만약 리얼터이시거나 리얼터와 함께 일하신다면 리얼터 혹은 부동산 회사를 알려 주시겠어요?`,
    disclaimer:
      '본인은 향후 개발 계획, 조기 분양 및 특별 제안에 대한 정보를 포함하여 Ledingham McAllister로부터 이메일을 수신하는 데 동의합니다.*',
    submit: '제출하기',
    required: '* 필수 기입 사항',
  },
  thankYou: {
    title: 'Thank You',
    text: 'Thank you for registering for Precidia by Ledingham McAllister. Your submission has been received and a representative will be in touch with you soon.',
  },
  notFound: {
    text: '404 - This page does not exist.',
  },
  privacy: {
    title: '개인정보 정책',
  },
};
