// Packages
import React from 'react';
import styled from 'styled-components';

// Components
import Intl from 'src/components/Intl';
import MarbleHero from 'src/components/MarbleHero';
import ContentBlock from 'src/components/ContentBlock';
import CenterBlock from 'src/components/CenterBlock';
import ToggleMap from './ToggleMap';
import ToggleMapMobile from './ToggleMapMobile';

const Amenities = () => {
  return (
    <Intl>
      {(t, { locale, getLocaleURL }) => {
        return (
          <Root>
            {/* Hero */}
            <MarbleHero
              title={t.amenities.hero.title}
              image={require('src/assets/images/gallery/interior/gallery_02.jpg')}
              intro={t.amenities.hero.intro}
              body={t.amenities.hero.body}
            />
            
            {/* Banner 1 */}
            <ContentBlock
              title={t.amenities.banner1.title}
              text={t.amenities.banner1.text}
              image={require('src/assets/images/amenities/video.png')}
            />

            {/* Banner 2 */}
            <ContentBlock
              title={t.amenities.banner2.title}
              body={t.amenities.banner2.body}
              gallery={[
                {
                  src: require('src/assets/images/amenities/gallery_1.jpg'),
                  alt: 'Precidia amenities'
                },
                {
                  src: require('src/assets/images/amenities/gallery_2.jpg'),
                  alt: 'Precidia amenities'
                },
                {
                  src: require('src/assets/images/amenities/gallery_3.jpg'),
                  alt: 'Precidia amenities'
                },
                {
                  src: require('src/assets/images/amenities/gallery_4.jpg'),
                  alt: 'Precidia amenities'
                }
              ]}
              reverse
            />

            <CenterBlock
              title={t.amenities.banner3.title}
            />
            
            {/* Toggle Map */}
            <ToggleMap />
            <ToggleMapMobile />
            
          </Root>
        );
      }}
    </Intl>
  );
};

export default Amenities;

const Root = styled.main``;
