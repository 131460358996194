import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';

import Intl from 'components/Intl';
import Button from 'src/components/Button';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

// Icons
import ArrowLeft from 'src/components/icons/ArrowLeft';
import ArrowRight from 'src/components/icons/ArrowRight';

// Slick styles
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Intro = () => {
  const [start, setStart] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);
  
  const hasLoaded = useSelector((state) => state.load.hasLoaded);

  useEffect(() => {
    if (hasLoaded) {
      setTimeout(() => setStart(true), 2300);
    }
  }, [hasLoaded]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <ArrowRight white />,
    prevArrow: <ArrowLeft white />,
    fade: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    afterChange: current => setCurrentSlide(current+1)
};
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <SliderContainer
            style={{
              opacity: start ? 1 : 0,
              transform: start ? 'translateY(0)' : 'translateY(10%)',
              transition: '0.7s ease'
            }}
          >
            <Slider {...settings}>
              <ImageWrapper>
                <Image
                  src={require('src/assets/images/homepage/precidia-exterior-rendering.jpeg')}
                  width={544}
                  height={750}
                />
              </ImageWrapper>
              <ImageWrapper wide>
                <Image
                  src={require('src/assets/images/gallery/exterior/gallery_02.jpg')}
                  width={1280}
                  height={750}
                />
              </ImageWrapper>
            </Slider>
            <Pagination>
              {currentSlide} / 2
            </Pagination>
          </SliderContainer>

          <Wrapper>
            <p className="title intro home">{t.home.hero.title}</p>
            <Body>
              <p className="body">{t.home.hero.text}</p>
            </Body>
          </Wrapper>

          <StyledLink
            to={`${getLocaleURL()}/floorplans`}
            className="button show-on-tablet"
          >
            {t.home.hero.button}
          </StyledLink>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  text-align: center;
  margin: 0 ${vwMobile(16)};

  @media ${media.desktop} {
    margin-top: ${vwDesktop(100)};
  }
`;

const Wrapper = styled.div`
  margin-top: ${vwMobile(35)};
  margin-bottom: ${vwMobile(20)};
  display: flex;
  flex-direction: column;
  text-align: center;

  .body {
    margin: auto;
  }
 
  @media ${media.tablet} {
    margin-top: ${vwTablet(35)};
    margin-bottom: ${vwTablet(40)};
    margin-left: ${vwTablet(60)};
    margin-right: ${vwTablet(60)};

    .body {
      width: ${vwTablet(419)};
    }
  }

  @media ${media.desktop} {
    margin: ${vwDesktop(72)} auto;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    max-width: ${vwDesktop(1062)};

    .title {
      width: ${vwDesktop(460)}
    }

    .body {
      width: ${vwDesktop(515)}
    }
  }
`;

const SliderContainer = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: ${vwMobile(391)};
  width: ${vwMobile(288)};

  @media ${media.tablet} {
    height: ${vwTablet(892)};
    width: ${vwTablet(652)};
  }

  @media ${media.desktop} {
    width: auto;
    height: ${vwDesktop(740)};
  }

  .slick-slider {
    margin-right: -3px;

    @media ${media.desktop} {
      height: ${vwDesktop(740)};
    }
  }
  
  .slick-arrow {
    position: absolute;
    ${vw('bottom', 18, 23, 27)};
    top: unset;
    z-index: 1;
  }

  .slick-prev {
    ${vw('left', 94, 255, 568)};
  }

  .slick-next {
    ${vw('right', 94, 255, 568)};
  }
`;

const Body = styled.div`
  position: relative;

  &::before {
    content: '';
    background-color: ${theme.color.blue};
    height: 1px;
    width: ${vwTablet(120)};
    margin-left: auto;
    margin-right: auto;
    ${vw('margin-top', 10, 40, 0)}
    ${vw('margin-bottom', 20, 40, 0)}
    display: block;

    @media ${media.desktop} {
      top: 0;
      position: absolute;
      width: 100%;
    }
  }

  @media ${media.desktop} {
    display: flex;
    align-items: flex-end;
  }
`;

const StyledLink = styled(Link)`
  z-index: 99999;
`;

const ImageWrapper = styled.div`
  margin-left: -2px;

  height: ${vwMobile(391)};
  width: ${vwMobile(288)};

  @media ${media.tablet} {
    height: ${vwTablet(892)};
    width: ${vwTablet(652)};
  }

  @media ${media.desktop} {
    height: ${vwDesktop(740)};
    width: auto;
  }
`;

const Image = styled.img`
  display: block;
  margin-top: ${vwMobile(37)};
  object-fit: cover;
  object-position: 85%;
  transition: 0.7s ease;
  width: 100%;
  height: 100%;

  @media ${media.desktop} {
    margin: auto;
    width: auto;
    height: ${vwDesktop(740)};
  }
`;

const Pagination = styled.div`
  background-color: white;
  ${vw('width', 30, 46)}
  ${vw('height', 30, 46)}
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 50%;
  color: ${({ theme }) => theme.color.blue};
  font-weight: 600;
  ${vw('font-size', 8, 11)}
  ${vw('bottom', 14, 16, 28)};
`;

export default Intro;
