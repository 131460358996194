// Packages
import React from 'react';
import styled from 'styled-components';
import ReactCompareImage from 'react-compare-image';

// Package
import Intl from 'src/components/Intl';

// Assets
import LeftImage from 'src/assets/images/gallery/interior/gallery_01a.jpg';
import RightImage from 'src/assets/images/gallery/interior/gallery_01.jpg';

// Styles
import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const DragBlock = () => (
    <Intl>
      {(t, { locale }) => (
        <Root>
          {/* Desktop & Tablet */}
          <CompareWrapper>
            <ReactCompareImage
              leftImage={LeftImage}
              rightImage={RightImage}
              sliderPositionPercentage={0.22}
            />
          </CompareWrapper>

          {/* Mobile Image */}
          <Image 
            width="288"
            src={LeftImage}
          />

          {/* Left label */}
          <SchemeLabel left>{t.interiors.banner.dark}</SchemeLabel>

          {/* Mobile Image */}
          <Image 
            width="288"
            src={RightImage}
          />

          {/* Right Label */}
          <SchemeLabel right>{t.interiors.banner.light}</SchemeLabel>

        </Root>
      )}
    </Intl>
);

export default DragBlock;

const Root = styled.div`
  ${vw('width', 292, 648, 1280)}
  margin: auto;
  position: relative;

  margin-top: ${vwMobile(-40)};

  @media ${media.tablet} {
    height: ${vwTablet(374)};
    margin-top: 0;
  }

  @media ${media.desktop} {
    height: ${vwDesktop(740)};
  }
`;

const CompareWrapper = styled.div`
  display: none;

  @media ${media.tablet} {
    display: unset;
  }
`;

const Image = styled.img`
  width: ${vwMobile(288)};
  margin: auto;
  margin-top: ${vwMobile(40)};

  @media ${media.tablet} {
    display: none;
  }
`;

const SchemeLabel = styled.span`
  text-transform: uppercase;
  ${vw('font-size', 11)}
  color: ${theme.color.blue};
  font-weight: bold;
  
  @media ${media.tablet} {
    position: absolute;
    bottom: ${vwTablet(-20)};
    left: ${(props) => props.left && vwTablet(0)};
    right: ${(props) => props.right && vwTablet(0)};
  }

  @media ${media.desktop} {
    background-color: white;
    padding: ${vwDesktop(5)} ${vwDesktop(14)};
    bottom: ${vwDesktop(20)};
    left: ${(props) => props.left && vwDesktop(20)};
    right: ${(props) => props.right && vwDesktop(20)};
  }
`;
