import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useSelector } from 'react-redux';

import { vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/taglineMobile';

const TaglineMobile = () => {
  const [start, setStart] = useState(false);

  const hasLoaded = useSelector((state) => state.load.hasLoaded);

  useEffect(() => {
    let timeout;
    if (hasLoaded) {
      timeout = setTimeout(() => setStart(true), 3000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [hasLoaded]);

  return (
    <Root>
      <SVG
        width="374px"
        height="200px"
        viewBox="0 0 374 200"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        start={start}
      >
        <title>elevate</title>
        <defs>
          <clipPath id="clip-elevate" transform="translate(2.00000, 0.40000)">
            {data.elevate.clip}
          </clipPath>
        </defs>
        <g
          id="mask"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          clipPath="url(#clip-elevate)"
        >
          {data.elevate.stroke}
        </g>
      </SVG>
      <SVG
        width="238px"
        height="204px"
        viewBox="0 0 238 204"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        start={start}
        second
      >
        <title>your</title>
        <defs>
          <clipPath id="clip-your" transform="translate(2.000000, 2.000000)">
            {data.your.clip}
          </clipPath>
        </defs>
        <g
          id="mask"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          clipPath="url(#clip-your)"
        >
          {data.your.stroke}
        </g>
      </SVG>
      <SVG
        width="272px"
        height="200px"
        viewBox="0 0 272 200"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        start={start}
        third
      >
        <title>living</title>
        <defs>
          <clipPath id="clip-living" transform="translate(0.000000, 1.000000)">
            {data.living.clip}
          </clipPath>
        </defs>
        <g
          id="mask"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          clipPath="url(#clip-living)"
        >
          {data.living.stroke}
        </g>
      </SVG>
    </Root>
  );
};

const animate = keyframes`
  0% {
    opacity: 1;
		stroke-dashoffset: 1200;
	}
	90% {
		stroke-dashoffset: 0;
	}
	100% {
    opacity: 1;
		stroke-dashoffset: 0;
	}
`;

const animateCss = css`
  animation-name: ${animate};
  animation-timing-function: linear;
  animation-fill-mode: forwards;
`;

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${media.desktop} {
    display: none;
  }
`;

const SVG = styled.svg`
  width: auto;
  height: ${vwMobile(90)};
  fill: none;
  margin-top: ${(props) => {
    if (props.second) {
      return vwMobile(-15);
    } else if (props.third) {
      return vwMobile(-20);
    } else {
      return 0;
    }
  }};
  margin-right: ${(props) => {
    if (props.second) {
      return vwMobile(4);
    }
  }};
  margin-left: ${(props) => props.third && vwMobile(6)};
  align-self: ${(props) => {
    if (props.second) {
      return 'flex-end';
    } else if (props.third) {
      return 'flex-start';
    } else {
      return 'center';
    }
  }};
  @media ${media.tablet} {
    height: ${vwTablet(160)};
    margin-top: ${(props) => {
      if (props.second) {
        return vwTablet(-20);
      } else if (props.third) {
        return vwTablet(-30);
      } else {
        return 0;
      }
    }};
    margin-right: ${(props) => {
      if (props.second) {
        return vwTablet(6);
      }
    }};
    margin-left: ${(props) => props.third && vwTablet(16)};
  }
  #mask path {
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
    opacity: 0;
    ${(props) => props.start && animateCss}
  }
  /* ELEVATE */
  #stroke-1 {
    animation-duration: 1s;
  }
  #stroke-2 {
    animation-duration: 0.05s;
    animation-delay: 0.25s;
  }
  #stroke-3 {
    animation-duration: 0.5s;
    animation-delay: 0.4s;
  }
  #stroke-4 {
    animation-duration: 0.05s;
    animation-delay: 0.6s;
  }
  #stroke-5 {
    animation-duration: 0.4s;
    animation-delay: 0.6s;
  }
  #stroke-6 {
    animation-duration: 0.25s;
    animation-delay: 0.7s;
  }
  #stroke-7 {
    animation-duration: 0.5s;
    animation-delay: 0.9s;
  }
  #stroke-8 {
    animation-duration: 0.2s;
    animation-delay: 1.1s;
  }
  #stroke-9 {
    animation-duration: 0.2s;
    animation-delay: 1.2s;
  }
  #stroke-10 {
    animation-duration: 0.3s;
    animation-delay: 1.3s;
  }

  /* YOUR */
  #stroke-11 {
    animation-duration: 0.6s;
    animation-delay: 2.1s;
  }
  #stroke-12 {
    animation-duration: 0.3s;
    animation-delay: 2.4s;
  }
  #stroke-13 {
    animation-duration: 0.4s;
    animation-delay: 2.6s;
  }
  #stroke-14 {
    animation-duration: 0.5s;
    animation-delay: 2.8s;
  }

  /* LIVING */
  #stroke-15 {
    animation-duration: 0.8s;
    animation-delay: 3.2s;
  }
  #stroke-16 {
    animation-duration: 0.3s;
    animation-delay: 3.4s;
  }
  #stroke-17 {
    animation-duration: 0.1s;
    animation-delay: 4.5s;
  }
  #stroke-18 {
    animation-duration: 0.3s;
    animation-delay: 3.5s;
  }
  #stroke-19 {
    animation-duration: 0.2s;
    animation-delay: 3.7s;
  }
  #stroke-20 {
    animation-duration: 0.1s;
    animation-delay: 4.6s;
  }
  #stroke-21 {
    animation-duration: 0.7s;
    animation-delay: 3.8s;
  }
`;

export default TaglineMobile;
