export default {
  exterior: [
    {
      thumb: require('src/assets/images/gallery/exterior/gallery_01_thumb.jpg'),
      full: require('src/assets/images/gallery/exterior/gallery_01.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/exterior/gallery_01a_thumb.jpg'),
      full: require('src/assets/images/gallery/exterior/gallery_01a.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/exterior/gallery_02_thumb.jpg'),
      full: require('src/assets/images/gallery/exterior/gallery_02.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/exterior/gallery_03_thumb.jpg'),
      full: require('src/assets/images/gallery/exterior/gallery_03.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/exterior/gallery_04_thumb.jpg'),
      full: require('src/assets/images/gallery/exterior/gallery_04.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/exterior/gallery_05_thumb.jpg'),
      full: require('src/assets/images/gallery/exterior/gallery_05.jpg'),
    },
  ],
  interior: [
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_02_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_02.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_03_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_03.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_04_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_04.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_05_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_05.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_06_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_06.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_07_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_07.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_08_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_08.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_09_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_09.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_10_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_10.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_11_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_11.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_12_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_12.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_13_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_13.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/display_suites/gallery_14_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/display_suites/gallery_14.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/gallery_02_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/gallery_02.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/gallery_03_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/gallery_03.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/gallery_04_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/gallery_04.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/gallery_05_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/gallery_05.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/interior/gallery_06_thumb.jpg'),
      full: require('src/assets/images/gallery/interior/gallery_06.jpg'),
    },
  ],
  neighbourhood: [
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_01_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_01.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_02_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_02.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_03_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_03.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_04_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_04.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_05_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_05.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_06_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_06.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_07_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_07.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_08_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_08.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_09_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_09.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_10_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_10.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_11_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_11.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_12_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_12.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_13_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_13.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_14_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_14.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_15_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_15.jpg'),
    },
    {
      thumb: require('src/assets/images/gallery/neighbourhood/gallery_16_thumb.jpg'),
      full: require('src/assets/images/gallery/neighbourhood/gallery_16.jpg'),
    },
  ],
};
