import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

// Components
import Spotlight from 'src/components/Spotlight';

// Icons
import ArrowLeft from 'src/components/icons/ArrowLeft';
import ArrowRight from 'src/components/icons/ArrowRight';

// Styles
import vw, { vwDesktop } from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

// Slick styles
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Slick = (props) => {
  const [showSpotlight, setShowSpotlight] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const clickHandler = (index) => {
    setShowSpotlight(true);
    setCurrentIndex(index)
  };

  const closeSpotlight = () => 
    setShowSpotlight(false);
  
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Root>
      {
        showSpotlight &&
        <Spotlight
          title={props.title}
          closeHandler={closeSpotlight}
          images={props.images}
          index={currentIndex}
        />
      }
      <Title>{props.title}</Title>
      <Slider {...settings}>
        {
          props.images.map((image, index) => (
          <Card key={index} onClick={() => clickHandler(index)} >
            <CardContent>
              <CardImage className="card-image" bg={image.thumb}></CardImage>
              <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="14" stroke="white" stroke-width="2" stroke-linecap="square"/>
                <path d="M15.3718 18.6176V15.3719H18.6176V14.2458H15.3718V11H14.2458V14.2458H11V15.3719H14.2458V18.6176H15.3718Z" fill="white"/>
              </Svg>
            </CardContent>

            <p className="caption">{image.caption}</p>
          </Card>
          ))
        }
      </Slider>
    </Root>
  );
};

const Root = styled.div`
  ${vw('padding-left', 16, 60, 100)}
  ${vw('padding-right', 16, 60, 100)}
  ${vw('margin-top', 60, 80, 160)}
  ${vw('margin-bottom', 60, 80, 160)}
  
  .slick-slider {
    ${vw('margin-left', 18, -15)}
    ${vw('margin-right', 18, -15)}
  }
`;

const Title = styled.h2`
  text-transform: uppercase;
  color: ${theme.color.grey_dark};
  ${vw('margin-bottom', 48, 80)}
  text-align: left;
  position: relative;

  &::after {
    content: '';
    background-color: ${theme.color.blue};
    height: 1px;
    width: 100%;
    display: block;
    position: absolute;
    ${vw('bottom', -20, -40)};

    @media ${media.desktop} {
      width: ${vwDesktop(603)};
      right: 0;
      bottom: 0;
    }
  }
`;

const Card = styled.div`
  ${vw('padding-left', 0, 15)}
  ${vw('padding-right', 0, 15)}
  text-align: center;
  
  @media ${media.tablet} {
    text-align: left;
  }
`;

const CardContent = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  ${vw('height', 148, 200)}
  ${vw('margin-bottom', 8, 12)}

  &:focus,
  &:hover {
    .card-image {
      transform: scale(1.1);
    }

    svg circle {
      fill: ${theme.color.blue};
    }
  }
`;

const Svg = styled.svg`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-25%);
  ${vw('width', 30, 30, 40)}
  ${vw('height', 30, 30, 40)}

  circle {
    transition: all 0.8s ease 0s;
  }
`;

const CardImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.bg});
  background-position: center;
  background-size: cover;
  transition: all 0.8s ease 0s;

  &:hover {
  }
`;

export default Slick;
