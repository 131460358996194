import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import MarbleHero from 'src/components/MarbleHero';
import Slick from './Slick';

import Logo from 'src/assets/images/logos/ledmac-logo.svg'

import data from 'src/data/developer';

import vw from 'src/styles/utils';

const Hero = () => {
  return (
     <Intl>
      {(t, { getLocaleURL, locale }) => (
      <MarbleHero>
        <Root>
          <ImageWrapper>
            <Image src={Logo} width="400" />
            <h1>{t.developer.hero.title}</h1>
          </ImageWrapper>

          <TextWrapper>
            <p className="body">{t.developer.hero.body}</p>
          </TextWrapper>
          
          <Slick images={data} />
        </Root>
      </MarbleHero>
    )}
    </Intl>
  );
};

export default Hero;

const Root = styled.div`
  h1 {
    text-align: center;
    ${vw('margin-top', 35, 67, 90)}
    ${vw('margin-bottom', 32, 20)}
    font-weight: 300;
    ${vw('font-size', 15, 20)}
    text-transform: uppercase;
    color: #4B4E52;
  }
`;

const ImageWrapper = styled.div`
  ${vw('padding-left', 16, 24, 48)}
  ${vw('padding-right', 16, 24, 48)}
`;

const Image = styled.img`
  ${vw('width', 180, 321)}
`;

const TextWrapper = styled.div`
  ${vw('width', 288, 600, 1026)}
  margin: auto;

  p {
    margin: 0;
  }
`;
