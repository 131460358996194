import React from 'react';
import Counter from 'components/Counter';

const About = () => {
  return (
    <main>
      <h1>About</h1>
      <Counter />
    </main>
  );
};

export default About;
