import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import MarbleHero from 'src/components/MarbleHero';
import FloorplansTable from './FloorplansTable';
import Views from './Views';

import media from 'src/styles/media';

const Floorplans = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            <MarbleHero title={t.floorplans.title}>
              <FloorplansTable />
            </MarbleHero>
            <Views />
          </Root>
        );
      }}
    </Intl>
  );
};

const Root = styled.main``;

export default Floorplans;
