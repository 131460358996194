import React from 'react';
import styled from 'styled-components';

import FullLogo from 'src/assets/images/logos/precidia-logo-blue.svg';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

function Logo() {
  return (
    <Root src={FullLogo}>
    </Root>
  );
};

export default Logo;

const Root = styled.img`
  ${vw('width', 100, 160, 223)}
  max-width: 223px;
  height: auto;
  transition: 0.2s all ease;

  @media ${media.desktop} {
    // padding-top: ${vwDesktop(2)}
  }
`;
