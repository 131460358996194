import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';
import { useDispatch, useSelector } from 'react-redux';
import { setHasLoaded } from 'src/redux/load';

import vw from 'src/styles/utils';

const LoadingScreen = () => {
  const dispatch = useDispatch();

  const hasLoaded = useSelector((state) => state.load.hasLoaded);

  const $loader = useRef();

  const handleLoad = () => {
    enableBodyScroll($loader.current);
    dispatch(setHasLoaded(true));
  };

  useEffect(() => {
    disableBodyScroll($loader.current);
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
      clearAllBodyScrollLocks();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root
      ref={$loader}
      style={{ opacity: hasLoaded && !window.__PRERENDERING ? 0 : 1 }}
    >
      <img
        src={require('src/assets/images/logos/precidia-logo-white.svg')}
        alt="Precidia"
        style={{
          opacity: hasLoaded && !window.__PRERENDERING ? 1 : 0,
          transform: hasLoaded ? 'scale(1)' : 'scale(0.9)',
        }}
      />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${({ theme }) => theme.transition};
  transition-delay: 1.4s;
  z-index: 999;
  pointer-events: none;
  img {
    transition: 0.8s ease;
    transform-origin: 50% 50%;
    ${vw('width', 186, 298, 354)}
  }
`;

export default LoadingScreen;
