import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';

const SubitemList = (props) => {
  const { list } = props;
  return (
    <Root>
    {
      list.map((item, index) => (
        <p key={item + index}>{item}</p>
      ))
    }
    </Root>
  );
};

SubitemList.propTypes = {
  list: PropTypes.array
};

const Root = styled.div`
  font-style: normal;
  font-weight: normal;
  ${vw('font-size', 12)}
  ${vw('line-height', 16)}
  ${vw('margin-top', 10)}
  color: #444444;
`;

export default SubitemList;
