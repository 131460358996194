import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseSVG } from 'src/assets/images/popup/close_icon.svg';
import Intl from 'src/components/Intl';
import vw, { hexToRGBA } from '../styles/utils';
import media from '../styles/media';
import { useHistory } from 'react-router-dom';

const Popup = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 3000);
  }, []);

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root open={open} onClick={() => setOpen(false)}>
          <Container
          // onClick={(e) => e.stopPropagation()}
          >
            <Close onClick={() => setOpen(false)} />
            <PopupContainer
              onClick={(e) => {
                e.stopPropagation();
                history.push(`${getLocaleURL()}/register`);
                setOpen(false);
              }}
            />
            {/* <React.Fragment               onClick={() => {
                history.push(`${getLocaleURL()}/register`);
                setOpen(false);
              }}> */}
          </Container>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  background: ${({ theme }) => hexToRGBA(theme.color.grey_dark, 0.7)};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  transition: all 0.5s;
`;

const Container = styled.div`
  position: relative;
  ${vw('width', 340 * 0.88, 708, 708 * 0.81)}
  ${vw('height', 360 * 0.88, 598, 598 * 0.81)}
`;

const PopupContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  background-image: url(${require('src/assets/images/popup/mobile.jpg')});
  background-size: cover;
  background-position: center;

  @media ${media.tablet} {
    background-image: url(${require('src/assets/images/popup/desktop.jpg')});
  }
`;

const Close = styled(CloseSVG)`
  position: absolute;
  cursor: pointer;
  z-index: 2;
  ${vw('top', 20, 25, 25)}
  ${vw('right', 20, 25, 25)}
  ${vw('width', 20, 24, 20)}
  ${vw('height', 20, 24, 20)}
`;

export default Popup;
