export default [
  {
    id: 'ibi',
    name: 'ARCADIS | IBI GROUP',
    logo: 'Arcadis_Logo.png',
    logoHeightDesktop: 40,
    logoHeightMobile: 32,
    link: '/',
  },
  {
    id: 'pwl',
    name: 'PWL Partnership',
    logo: 'logo_pwl.png',
    logoHeightDesktop: 77,
    logoHeightMobile: 50,
    link: '/',
  },
  {
    id: 'themill',
    name: 'The MILL DESIGN GROUP',
    logo: 'logo_themill.png',
    logoHeightDesktop: 47,
    logoHeightMobile: 50,
    link: '/',
  },
];
