// Packages
import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

// Components
import Intl from 'src/components/Intl';
import Spotlight from './Spotlight';
import ArrowLeft from 'src/components/icons/ArrowLeft';
import ArrowRight from 'src/components/icons/ArrowRight';

// Data
import data from 'src/data/amenities';

// Asset
import HotSpotIcon from 'src/assets/images/amenities/birdeyes/hotspot.png';

// Styles
import vw, { vwMobile } from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

// Slick styles
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const ToggleMap = () => {
  const [activeLevel, setActiveLevel] = useState(1);
  const [showSpotlight, setShowSpotlight] = useState(false);
  const [spotlightImage, setSpotlightImage] = useState('');
  const [spotlightTitle, setSpotlightTitle] = useState('');
  
  const levelClickHandler = (e, index) => {
    e.preventDefault();
    setActiveLevel(index);
  };

  const hotSpotClickHandler = (data) => {
    setShowSpotlight(true);
    setSpotlightImage(data.image)
    setSpotlightTitle(data.title)
  }

  const closeSpotlight = () => 
    setShowSpotlight(false);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <StyledArrowRight />,
    prevArrow: <StyledArrowLeft />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  return (
    <Intl>
      {(t, { locale, getLocaleURL }) => {
        return (
          <Root>
            {
              showSpotlight &&
              <Spotlight
                closeHandler={closeSpotlight}
                image={spotlightImage}
                title={spotlightTitle}
              />
            }

            <Slider {...settings}>
            {
              data.map(level => (
                <Slide>
                  <Title>{level.title}</Title>
                  <BirdeyeContainer>
                    <Birdeye src={level.levelImage} />
                    {
                      level.hotSpots.map((hotSpot) => (
                        <HotSpot
                          onClick={() => hotSpotClickHandler(hotSpot)}
                          src={HotSpotIcon}
                          alt="call out"
                          positions={hotSpot.positions}
                        />
                      ))
                    }
                  </BirdeyeContainer>
                </Slide>
              ))
            }
            </Slider>
          </Root>
        );
      }}
    </Intl>
  );
};

export default ToggleMap;

const Root = styled.div`
  text-align: center;
  padding-bottom: ${vwMobile(60)};

  @media ${media.tablet} {
    display: none;
  }
`;

const Title = styled.a`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${vwMobile(18)};
  letter-spacing: ${vwMobile(1)};
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  color: ${theme.color.blue};
  margin-bottom: ${vwMobile(40)};
`;

const Slide = styled.div``;

const BirdeyeContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: auto;
  width: ${vwMobile(288)};
  height: ${vwMobile(207)};
`;

const Birdeye = styled.img`
  width: 100%;
  height: 100%;
`;

const HotSpot = styled.img`
  background-image: url(${HotSpotIcon});
  position: absolute;
  ${vw('width', 50, 70, 90)};
  ${vw('height', 50, 70, 90)};
  top: ${props => `${props.positions[1]/1440 * 100}%`};
  left: ${props => `${props.positions[0]/1980 * 100}%`};
  cursor: pointer;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  &.slick-prev {
    left: ${vwMobile(28)};
    top: ${vwMobile(10)};
  }
`;

const StyledArrowRight = styled(ArrowRight)`
  &.slick-next {
    right: ${vwMobile(28)};
    top: ${vwMobile(10)};
  }
`;
