import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

const TableHeader = () => (
  <Intl>
    {(t) => (
      <Root>
        <span className="plan">{t.floorplans.header.plan}</span>
        <span className="type">{t.floorplans.header.type}</span>
        <span className="sqft">{t.floorplans.header.sqft}</span>
      </Root>
    )}
  </Intl>
);

const Root = styled.div`
  display: none;
  font-family: ${theme.font.family};
  line-height: 22px;
  ${vw('font-size', 16)};
  ${vw('padding-bottom', 16)}
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: #4B4E52;
  align-items: center;
  justify-content: space-between;

  @media ${media.tablet} {
    display: flex;
    border-bottom: 1px solid ${theme.color.blue};
  }

  span {
    text-align: left;

    &.plan {
      width: 28%;
      text-align: center;
    }

    &.type {
      width: 48%;
    }

    &.sqft {
      width: 24%;
    }
  }
`;

export default TableHeader;
