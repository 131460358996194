/* eslint-disable quote-props */
import React from 'react';

export default [
  {
    id: 'a',
    label: 'a',
    indoor: '625',
    outdoor: '80',
    total: '705',
    bed: '1',
    bath: '1',
    den: true,
    filters: ['one bedroom'],
    show: true,
    typeId: 'oneBedOneDen'
  },
  {
    id: 'a1',
    label: 'a1',
    indoor: '625',
    outdoor: '80',
    total: '705',
    bed: '1',
    bath: '1',
    den: true,
    type: 'adaptable',
    dropdown: 'adaptable',
    filters: ['one bedroom'],
    show: true,
    typeId: 'oneBedOneDen'
  },
  {
    id: 'b1',
    label: 'b1',
    indoor: '751',
    outdoor: '92',
    total: '843',
    bed: '2',
    bath: '2',
    den: false,
    filters: ['two bedroom'],
    show: true,
    typeId: 'twoBed'
  },
  {
    id: 'c',
    label: 'c',
    indoor: '843-847',
    outdoor: '200-201',
    total: '1,043-1,048',
    bed: '2',
    bath: '2',
    den: false,
    filters: ['two bedroom'],
    show: true,
    typeId: 'twoBed'
  },
  {
    id: 'c1_8-27',
    label: 'c1',
    indoor: '808-816',
    outdoor: '136-144',
    total: '944-960',
    bed: '2',
    bath: '2',
    den: false,
    type: 'Levels 8 to 27',
    dropdown: '8 to 27',
    filters: ['two bedroom'],
    typeId: 'twoBed'
  },
  {
    id: 'c2',
    label: 'c2',
    indoor: '819',
    outdoor: '136-143',
    total: '955-962',
    bed: '2',
    bath: '2',
    den: false,
    type: 'adaptable',
    dropdown: 'adaptable',
    filters: ['two bedroom'],
    typeId: 'twoBed'
  },
  {
    id: 'd',
    label: 'd',
    indoor: '928',
    outdoor: '237-243',
    total: '1,165-1,171',
    bed: '3',
    bath: '2',
    den: false,
    filters: ['three bedroom'],
    typeId: 'threeBed'
  }
];
