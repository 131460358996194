import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { vwDesktop } from 'src/styles/utils';

const Compass = (props) => {
  const { angle, activeLevelImage } = props;
  const [direction, setDirection] = useState(0);

  useEffect(() => {
    setDirection(angle)
  }, [angle])

  return (
    <Root>
      <svg width="97" height="117" viewBox="0 0 97 117" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* Circle */}
        <path opacity="0.745698" fill-rule="evenodd" clip-rule="evenodd" d="M49.137 115.973C74.5272 115.973 95.11 95.3901 95.11 70C95.11 44.6098 74.5272 24.027 49.137 24.027C23.7469 24.027 3.16406 44.6098 3.16406 70C3.16406 95.3901 23.7469 115.973 49.137 115.973Z" stroke="white" stroke-width="2"/>
        <path d="M47.5979 9.57829V4.26169L51.8822 9.57829H53.5149V0.821533H51.5508V6.13814L47.2788 0.821533H45.6338V9.57829H47.5979Z" fill="white"/>

        {/* Level 29 */}
        <g id="Group" transform="translate(30, 55)">
          <path d="M2.23395 5H3.53395L3.53395 10.9L7.19395 10.9V12L2.23395 12L2.23395 5ZM14.9703 10.91V12H9.72027L9.72027 5L14.8303 5L14.8303 6.09L11.0203 6.09V7.91H14.4003V8.98H11.0203V10.91H14.9703ZM24.2218 5L21.1718 12H19.8918L16.8318 5H18.2418L20.5718 10.4L22.9218 5H24.2218ZM31.7105 10.91L31.7105 12L26.4605 12L26.4605 5L31.5705 5V6.09L27.7605 6.09V7.91H31.1405V8.98L27.7605 8.98V10.91L31.7105 10.91ZM34.6597 5H35.9597V10.9H39.6197V12H34.6597V5Z" fill="white"/>
          <path d="M18.2871 30.02V32H8.99914V30.434L14.0031 25.682C14.5671 25.142 14.9451 24.674 15.1371 24.278C15.3411 23.87 15.4431 23.468 15.4431 23.072C15.4431 22.484 15.2451 22.034 14.8491 21.722C14.4531 21.41 13.8711 21.254 13.1031 21.254C11.8191 21.254 10.8351 21.692 10.1511 22.568L8.51314 21.308C9.00514 20.648 9.66514 20.138 10.4931 19.778C11.3331 19.406 12.2691 19.22 13.3011 19.22C14.6691 19.22 15.7611 19.544 16.5771 20.192C17.3931 20.84 17.8011 21.722 17.8011 22.838C17.8011 23.522 17.6571 24.164 17.3691 24.764C17.0811 25.364 16.5291 26.048 15.7131 26.816L12.3471 30.02H18.2871ZM25.3857 19.22C27.1137 19.22 28.4517 19.76 29.3997 20.84C30.3597 21.92 30.8397 23.468 30.8397 25.484C30.8397 26.9 30.5817 28.112 30.0657 29.12C29.5497 30.116 28.8237 30.878 27.8877 31.406C26.9637 31.922 25.8897 32.18 24.6657 32.18C24.0177 32.18 23.4057 32.114 22.8297 31.982C22.2657 31.838 21.7737 31.634 21.3537 31.37L22.2177 29.606C22.8417 30.014 23.6397 30.218 24.6117 30.218C25.8237 30.218 26.7717 29.846 27.4557 29.102C28.1397 28.358 28.4817 27.284 28.4817 25.88V25.862C28.1097 26.294 27.6357 26.624 27.0597 26.852C26.4837 27.068 25.8357 27.176 25.1157 27.176C24.2877 27.176 23.5437 27.02 22.8837 26.708C22.2237 26.384 21.7077 25.928 21.3357 25.34C20.9637 24.752 20.7777 24.074 20.7777 23.306C20.7777 22.49 20.9817 21.776 21.3897 21.164C21.7977 20.54 22.3497 20.06 23.0457 19.724C23.7417 19.388 24.5217 19.22 25.3857 19.22ZM25.5657 25.34C26.3217 25.34 26.9337 25.136 27.4017 24.728C27.8817 24.32 28.1217 23.804 28.1217 23.18C28.1217 22.568 27.8877 22.064 27.4197 21.668C26.9637 21.26 26.3277 21.056 25.5117 21.056C24.7797 21.056 24.1857 21.254 23.7297 21.65C23.2737 22.034 23.0457 22.55 23.0457 23.198C23.0457 23.858 23.2737 24.38 23.7297 24.764C24.1857 25.148 24.7977 25.34 25.5657 25.34Z" fill="white"/>
        </g>

        <Direction angle={-direction} d="M49.2195 21.1809V26.5136C25.0818 26.5136 5.51436 46.0811 5.51436 70.2187H0.181641C0.181641 43.1359 22.1366 21.1809 49.2195 21.1809Z" fill="white"/>
      </svg>
    </Root>
  )
}

Compass.propTypes = {

}

const Root = styled.div`
  z-index: 9;
  pointer-events: none;
  position: absolute;
  right: ${vwDesktop(60)};
  top: ${vwDesktop(64)};

  > img {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  > svg {
    overflow: initial;
  }
`;

const Direction = styled.path`
  transform: ${props => `translate(0px, 0px) rotate(${props.angle+90}deg)`};
  transform-origin: 50% 60%;
`;

export default Compass;
