// Packages
import React from 'react';
import styled from 'styled-components';

// Components
import Intl from 'src/components/Intl';
import ScriptBanner from 'src/components/ScriptBanner';
import CenterBlock from 'src/components/CenterBlock';
import DragBlock from './DragBlock';

// Styles
import vw from 'src/styles/utils';

const DragBanner = () => (
    <Intl>
      {(t, { locale }) => (
        <Root>
          <ScriptBanner 
            image={require('src/assets/images/interiors/script_header.svg')}
            localeText={t.interiors.banner.title}
          />
          <Intro className="body">
            {t.interiors.banner.body}
          </Intro>

          <DragBlock />

          <CenterBlock
            text={t.interiors.banner2.text}
            buttonLabel={t.interiors.banner2.buttonLabel}
            buttonTo="/Precidia_Features_Sheet.pdf"
            download={true}
          />

        </Root>
      )}
    </Intl>
);

export default DragBanner;

const Root = styled.div``;

const Intro = styled.p`
  ${vw('width', 288, 520)};
  margin: auto;
  ${vw('margin-bottom', 20, 60)}
  text-align: center;
`;
