import React, { useState } from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import Accordion from './Accordion';

import data from 'src/data/floorplans';
import vw from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

const FloorplansWrapper = () => {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
    <Root>
      {
        data.map(({
          label,
          id,
          typeId,
          indoor
        }) => (
          <Accordion
            className="accordion"
            unitId={id}
            label={label}
            type={t.floorplans.types[typeId]}
            sqft={indoor}
            unitPng={require(`../../assets/images/floorplans/png/${id}.png`)}
            pdfLink={`/floorplans/${id}.pdf`}
          />
        ))
      }

      <Disclaimer>
        In a continuing effort to meet the challenge of product improvements, we reserve the right to modify or change dimensions, sizes, specifications, plan orientations and materials without notice. All patio, terrace and balcony sizes may vary. Windows, columns, privacy screens, demising walls and guardrail details may vary depending upon the plan and floor level. The quality homes at Precidia are built by LM Precidia Homes Limited Partnership E. & O.E.
      </Disclaimer>
    </Root>
          )}
    </Intl>
  );
};

const Root = styled.div`
  @media ${media.tablet} {
    border-top: 1px solid ${theme.color.blue};
  }
`;

const Disclaimer = styled.p`
  color:  #4B4E52;
  font-style: normal;
  font-weight: 300;
  ${vw('font-size', 9, 10)}
  ${vw('line-height', 14, 16)}
  ${vw('padding-top', 16)}
  line-height: 13px;
  text-align: justify;

  @media ${media.tablet} {
    border-top: 1px solid ${theme.color.blue};
  }
`;

export default FloorplansWrapper;
