import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

function PhotoFullWidth(props) {
  const { src } = props;
  return (
    <Root src={src}></Root>
  );
};

const Root = styled.img`
  width: 100%;
  ${vw('width', 288, 720, 1344)}
  ${vw('height', 154, 388, 720)}
  border: 8px solid white;
  ${vw('border-width', 8, 12, 14)}
  object-fit: cover;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
`;

export default PhotoFullWidth;
