import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import Logo from 'src/assets/images/logos/precidia-logo-blue-wordmark.svg';

function CompactLogo() {
  return (
    <Root src={Logo} width="148">
    </Root>
  );
};

export default CompactLogo;

const Root = styled.img`
  ${vw('width', 80, 119, 223)}
  ${vw('height', 28, 41, 45)}
  transition: 0.2s all ease;
`;
