import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Image from 'src/assets/images/neighbourhood/restaurant.jpg';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

const Map = (props) => {
  const { activeCategory, activePoint, handlePoint, data } = props;
  return (
    <Root>
      <img
        src={Image}
        alt=""
        className="map"
      />
      {data.map((category, index) => (
        <Wrapper
          key={index}
          style={{
            opacity: activeCategory === index ? 1 : 0,
            pointerEvents: activeCategory === index ? 'all' : 'none',
          }}
        >
          {category.points.map((point, p) => (
            <Point
              key={p}
              top={point.top}
              left={point.left}
              active={activePoint === p}
              onClick={() => handlePoint(p)}
              bottomCallout={point.bottomCallout}
            >
              <span className="poi-number white">{p + 1}</span>
              {/* {point.arrow && (
                <Arrow
                  src={require('@/assets/images/neighbourhood/poi_arrow.svg')}
                  alt="Arrow"
                  transform={point.transform}
                  left={point.arrowLeft}
                  top={point.arrowTop}
                />
              )}
              <div>
                <p className="photo-caption grey">{point.text}</p>
              </div> */}
            </Point>
          ))}
        </Wrapper>
      ))}
    </Root>
  );
};

Map.propTypes = {
  activeCategory: PropTypes.number,
  activePoint: PropTypes.number,
  handlePoint: PropTypes.func,
  data: PropTypes.array,
};

const Root = styled.div`
  width: ${vw(1300)};
  height: ${vw(1000)};
  position: absolute;
  top: ${vw(40)};
  left: ${vw(40)};
  .map {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.4s ease;
`;

const Point = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  width: ${vw(39)};
  height: ${vw(39)};
  z-index: ${(props) => (props.active ? 2 : 1)};
  span {
    width: ${vw(33)};
    height: ${vw(33)};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${(props) =>
      props.active ? theme.color.blue : theme.color.cc_grey};
    border: ${vw(3)} solid white;
    box-shadow: 0 ${vw(5)} ${vw(15)} 0 ${theme.color.cc_grey};
    transition: 0.4s ease;
    position: relative;
    z-index: 2;
  }
  div {
    position: absolute;
    bottom: ${(props) =>
      props.bottomCallout ? 'auto' : `calc(100% + ${vw(70)})`};
    top: ${(props) =>
      props.bottomCallout ? `calc(100% + ${vw(70)})` : 'auto'};
    left: 50%;
    transform: translateX(-50%);
    min-width: 0;
    max-width: ${vw(230)};
    width: max-content;
    padding: ${vw(12)} ${vw(20)};
    background-color: white;
    box-shadow: 0 ${vw(5)} ${vw(15)} 0 ${theme.color.cc_grey};
    opacity: ${(props) => (props.active ? 1 : 0)};
    pointer-events: none;
    transition: 0.4s ease;
    &::after {
      content: '';
      width: ${vw(3)};
      height: ${vw(82)};
      background-color: white;
      position: absolute;
      top: ${(props) => (props.bottomCallout ? 'auto' : '100%')};
      bottom: ${(props) => (props.bottomCallout ? '100%' : 'auto')};
      left: 50%;
      transform: translateX(-50%);
    }
    p {
      flex: 1;
    }
  }
`;

export default Map;
