// Packages
import React from 'react';
import styled from 'styled-components';

// Components
import Intl from 'src/components/Intl';
import MarbleHero from 'src/components/MarbleHero';
import DragBanner from './DragBanner';

// Styles
import vw from 'src/styles/utils';

const Interiors = () => {
  return (
    <Intl>
      {(t, { locale }) => {
        return (
          <Root>
            {/* Hero */}
            <MarbleHero
              title={t.interiors.hero.title}
              image={require('src/assets/images/gallery/interior/gallery_01.jpg')}
              intro={t.interiors.hero.intro}
              body={t.interiors.hero.body}
            />
            
            {/* Drag Banner */}
            <DragBanner />
          </Root>
        );
      }}
    </Intl>
  );
};

export default Interiors;

const Root = styled.main`
  ${vw('padding-bottom', 20, 80)}
`;
