import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Intl from 'components/Intl';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

// This is meant to be used as the global Project Footer component for all LedMac projects. backgroundColor, logo, and column1, 2 and 3 are editable.

const Project = (props) => {
  const { color, bgColor, bgImage, logo, column1, column2, column3, project } = props;
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root color={color} bgColor={bgColor} bgImage={bgImage}>
          <Column active={true}>
            <Link to={getLocaleURL()}>
              <Logo src={logo} alt={project} className="logo" />
            </Link>
          </Column>
          <Column className="column-with-button" active={column1}>{column1 && column1}</Column>
          <Column className="column-with-button" active={column2}>{column2 && column2}</Column>
          <Column active={column3}>{column3 && column3}</Column>
        </Root>
      )}
    </Intl>
  );
};

Project.propTypes = {
  color: PropTypes.string,
};

const Root = styled.div`
  position: relative;
  background-color: ${(props) => props.bgColor};
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  padding: ${vwMobile(40)} ${vwMobile(16)};
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  .logo {
    ${vw('width', 200, 240)};
    height: auto;
    display: block;
  }
  .site-location {
    width: ${vwMobile(160)};
  }
  h3 {
    font-family: ${({ theme }) => theme.font.family};
    font-size: ${vwMobile(11)};
    font-weight: 500;
    line-height: 1.27;
    letter-spacing: ${vwMobile(1.38)};
    text-transform: uppercase;
    margin-bottom: ${vwMobile(12)};
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  .footer-link,
  p {
    font-family: ${({ theme }) => theme.font.sans_serif_family};
    font-size: ${vwMobile(14)};
    font-weight: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: white;
    display: block;
    margin-bottom: ${vwMobile(8)};
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .button {
    margin-top: ${vwTablet(16)}
  }

  @media ${media.tablet} {
    padding: ${vwTablet(60)} ${vwTablet(40)};
    .logo {
      width: ${vwTablet(170)};
    }
    .site-location {
      width: ${vwTablet(200)};
    }
    h3 {
      font-size: ${vwTablet(12)};
      line-height: 1.33;
      letter-spacing: ${vwTablet(3.5)};
      margin-bottom: ${vwTablet(16)};
      font-weight: bold;
    }
    .footer-link,
    p {
      font-size: ${vwTablet(14)};
      line-height: 1.43;
      margin-bottom: ${vwTablet(8)};
    }

    .button {
      margin-top: ${vwTablet(30)}
    }
  }
  @media ${media.desktop} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: ${vwDesktop(40)} ${vwDesktop(60)};
    .logo {
      width: ${vwDesktop(170)};
    }
    .site-location {
      width: ${vwDesktop(200)};
    }
    h3 {
      font-size: ${vwDesktop(14)};
      line-height: 1.33;
      letter-spacing: ${vwDesktop(3.5)};
      margin-bottom: ${vwDesktop(16)};
    }
    .footer-link,
    p {
      font-size: ${vwDesktop(14)};
      margin-bottom: ${vwDesktop(8)};
      transition: 0.4s ease;
    }

    .button {
      position: absolute;
      bottom: ${vwDesktop(100)}
    }
  }
`;

const Column = styled.div`
  text-align: center;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  margin-bottom: ${vwMobile(40)};

  &:last-of-type {
    margin-bottom: 0;
  }
  @media ${media.tablet} {
    margin-bottom: ${vwTablet(60)};
  }
  @media ${media.desktop} {
    display: flex;
    width: ${vwDesktop(300)};
    margin-bottom: 0;
    padding-top: ${vwDesktop(10)};
    &:first-of-type {
      padding-top: 0;
    }

    &.column-with-button {
      margin-top: 0;
      height: 172px;
    }
  }
`;

const Logo = styled.img`
  ${vw('width', 240)}
  height: auto;
`;

export default Project;
