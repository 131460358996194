import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import vw from './utils';
import media from './media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
    background-color: #FFFFFF;
    width: 100%;
    overflow-x: hidden;
  }

  body {
    font-family: ${theme.font.family};
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
  }

  main {
    width: 100%;
    height: 100%;
  }

  h1 {
    color: ${theme.color.blue};
    font-family: ${theme.font.family};
    ${vw('font-size', 26, 38)};
    ${vw('letter-spacing', 0.8)};
    ${vw('line-height', 34, 42)}
    text-transform: uppercase;
    font-weight: 300;

    &.localized {
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.16;
      text-align: center;
      @media ${media.tablet} {
        line-height: normal;
      }
    }
    &.privacy-policy {
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      ${vw('font-size', 32)}
      ${vw('line-height', 40)}
      ${vw('letter-spacing', 0.8)}
    }
  }

  h2 {
    font-family: ${theme.font.family};
    color: ${theme.color.grey_dark};
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    ${vw('font-size', 22, 32)}
    ${vw('line-height', 28, 40)}
    ${vw('letter-spacing', 0.55, 0.8)}
  }

  h3 {
    font-family: ${theme.font.family};
    font-weight: 400;
    text-transform: uppercase;
    ${vw('margin-bottom', 18, 21)}
    ${vw('font-size', 18)}
    ${vw('line-height', 20)}
    ${vw('letter-spacing', 1)}

    &.localized {
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      line-height: 1.25;
      ${vw('font-size', 32, 60, 60)}
      @media ${media.tablet} {
        line-height: normal;
      }
    }
  }

  a {
    color: ${theme.color.grey_dark};
  }

  p {
    font-family: ${theme.font.family};
    &.body {
      color: ${theme.color.grey_dark};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      ${vw('font-size', 13, 16)}
      ${vw('line-height', 22, 26)}
      ${vw('letter-spacing', 0.3, 0.4)}
    }
    &.ledmac-footer {
      font-family: ${theme.font.family};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      ${vw('font-size', 12, 13)}
      ${vw('line-height', 18, 20)}
      ${vw('letter-spacing', 0.22, 0.24)}
    }
    &.caption {
      color: ${theme.color.grey_dark};
      text-transform: uppercase;
      ${vw('font-size', 8, 12)};
      ${vw('line-height', 13, 14)};
      ${vw('letter-spacing', 1)};
      ${vw('margin-top', 4, 10)};
    }
  }

  .page {
    ${vw('padding-bottom', 40, 60, 80)}
  }

  .intro {
    color: ${theme.color.grey_dark};
    font-weight: 300;
    ${vw('font-size', 16, 21)};
    ${vw('line-height', 26, 34)};
    ${vw('letter-spacing', 0.2, 0.6)};

    &.home {
      ${vw('font-size', 18, 24)};
      ${vw('line-height', 32, 42)};
      ${vw('letter-spacing', 0.4, 0.6)};
    }
  }

  .disclaimer {
    color: ${theme.color.grey_dark};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    ${vw('font-size', 10, 11)}
    ${vw('line-height', 15, 18)}
    ${vw('letter-spacing', 0.18, 0.2)}
    text-align: left;
  }

  .ledmac-footer {
    &.subheader {
      font-family: ${theme.font.family};
      color: ${theme.color.grey_dark};
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      ${vw('font-size', 15)}
      ${vw('line-height', 22)}
      ${vw('letter-spacing', 0.25)}
      text-transform: unset;
    }
    &.link {
      font-family: ${theme.font.family};
      color: ${theme.color.grey_dark};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      ${vw('font-size', 11)}
      ${vw('line-height', 14, 16)}
      ${vw('letter-spacing', 1)}

      @media ${media.tablet} {
        text-align: left;
      }

      @media ${media.desktop} {
        text-decoration: none;
      }
    }
    &.disclaimer {
      font-family: ${theme.font.family};
      color: ${theme.color.grey_dark};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      ${vw('font-size', 10)}
      ${vw('line-height', 16)}
      ${vw('letter-spacing', 0.1)}
    }
  }

 .form {
    font-family: ${theme.font.family};
    &.input {
      color: ${theme.color.grey_dark};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      transition: ${theme.transition};
      ${vw('font-size', 14, 15)}
      ${vw('line-height', 18, 20)}
      ${vw('letter-spacing', 0.26, 0.28)}
      &.inactive {
        font-style: italic;
        color: #888a8d;
      }
    }
    &.label {
      text-align: left;
      color: ${theme.color.grey_dark};
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      ${vw('font-size', 11, 12)}
      ${vw('line-height', 16, 18)}
      ${vw('letter-spacing', 0.46, 0.5)}
    }
  }


  input:-webkit-autofill {
    border: 3px solid blue;
  }
  input:autofill {
    border: 3px solid blue;
  }

  .label {
    color: ${theme.color.grey_dark};
    font-weight: normal;
    font-stretch: normal;
    ${vw('font-size', 10, 12)}
    ${vw('line-height', 16, 22)}
  }

  .button {
    color: white;
    font-weight: 400
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    background-color: ${theme.color.blue};
    border: 1px solid ${theme.color.blue};
    ${vw('border-radius', 24)}
    ${vw('font-size', 11, 13)}
    ${vw('letter-spacing', 0.5, 1)}
    ${vw('padding-top', 5, 12)}
    ${vw('padding-bottom', 5, 12)}
    ${vw('padding-left', 18, 32)}
    ${vw('padding-right', 18, 32)}
    transition: ${({ theme }) => theme.transition};

    &:hover {
      color: ${theme.color.blue};
      background-color: white;
    }

    &:disabled {
      color: white;
      background-color: ${theme.color.grey_medium};
      border-color: ${theme.color.grey_medium};
    }
    
  }

  .container {
    ${vw('padding-left', 16, 0)}
    ${vw('padding-right', 16, 0)}
    ${vw('padding-top', 58, 120)}
    ${vw('padding-bottom', 128, 120)}
  }

  .hide-on-mobile {
    display: none;

    @media ${media.tablet} {
      display: unset;
    }
  }

  .show-on-mobile {
    display: unset;

    @media ${media.tablet} {
      display: none;
    }
  }
`;
