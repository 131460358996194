import { useEffect, useState } from 'react';

const useScrollDown = (inputThreshold) => {
  const [isScrollingDown, setIsScrollingDown] = useState(false);

  let lastPosition = 0;
  const threshold = inputThreshold || 100;

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e) => {
    let scrollPos = window.pageYOffset;

    // checks to see if user is scrolling up or down
    if (
      scrollPos > lastPosition &&
      e.target.scrollingElement.scrollTop > threshold
    ) {
      setIsScrollingDown(true);
    } else if (scrollPos < lastPosition) {
      setIsScrollingDown(false);
    }
    lastPosition = scrollPos;
  };

  return isScrollingDown;
};

export default useScrollDown;
