import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';

import vw, {vwDesktop} from 'src/styles/utils';
import media from 'src/styles/media';

const Welcome = () => {
  return (
    <Intl>
      {(t) => (
      <Root>
        <h1>{t.floorplans.welcome.title}</h1>
        <p className="body">
          {t.floorplans.welcome.body}
        </p>
      </Root>
      )}
    </Intl>
  )
};

const Root = styled.div`
  text-align: center;
  ${vw('margin-top', 32, 60, 110)}
  ${vw('margin-right', 0, 0, 0)}
  ${vw('margin-bottom', 32, 60)}
  ${vw('padding-left', 16, 117, 0)}
  ${vw('padding-right', 16, 117, 0)}

  @media ${media.desktop} {
    text-align: left;
    width: ${vwDesktop(300)};
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
    display: inline-block;
    vertical-align: top;
    margin-left: ${vwDesktop(110)}
  }

  .hide-on-mobile {
    display: none;

    @media ${media.tablet} {
      display: unset;
    }
  }
`;

export default Welcome;
