import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = (props) => {
  const { text, handleClick, to } = props;
  return (
    <Root
      to={to}
      className="button"
      {...props}
      onClick={handleClick}
    >
      {text}
    </Root>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  handleClick: PropTypes.func,
};

const Root = styled(Link)`
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  margin: auto 0;
  transition: ${({ theme }) => theme.transition};
  &:hover {
    color: ${({ theme }) => theme.color.blue};
    background-color: white;
  }
`;

export default Button;
