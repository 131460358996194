import appConfig from 'src/config/app.conf';
const { stage } = appConfig;

export default (property, wMobile, wTablet, wDesktop) => {
  if (typeof wTablet === 'undefined') {
    wTablet = wMobile;
  }

  if (typeof wDesktop === 'undefined') {
    wDesktop = wTablet;
  }

  if (
    property === 'font-size' ||
    property === 'line-height' ||
    property === 'letter-spacing'
  ) {
    return `
      ${property}: ${vwMobile(wMobile)};
      @media (min-width: ${appConfig.mediaQuery.tablet}px) {
        ${property}: ${vwTablet(wTablet)};
      }
      @media (min-width: ${appConfig.mediaQuery.desktop}px) {
        ${property}: ${vwDesktop(wDesktop)};
      }
      @media (min-width: ${appConfig.mediaQuery.xl}px) {
        ${property}: ${wDesktop * 1.2}px;
      }
    `;
  } else {
    return `
      ${property}: ${vwMobile(wMobile)};
      @media (min-width: ${appConfig.mediaQuery.tablet}px) {
        ${property}: ${vwTablet(wTablet)};
      }
      @media (min-width: ${appConfig.mediaQuery.desktop}px) {
        ${property}: ${vwDesktop(wDesktop)};
      }
    `;
  }
};

export const vwMobile = (width) => {
  return `${(width / stage.mobile) * 100}vw`;
};

export const vwTablet = (width) => {
  return `${(width / stage.tablet) * 100}vw`;
};

export const vwDesktop = (width) => {
  return `${(width / stage.desktop) * 100}vw`;
};

export const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const hexToRGBA = (hex, opacity) => {
  // This function is used to convert hex color to rgba color
  // Example: hexToRGBA('#000000', 0.5) => rgba(0, 0, 0, 0.5)
  // The color hex must be in the format of #000000, not #000
  // Opacity can be a number between 0 and 1, or a number between 0 and 100

  hex = hex.replace('#', '');

  if (opacity > 1) {
    opacity = opacity / 100;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};
