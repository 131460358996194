import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import PhotoLandscape from 'src/components/PhotoLandscape';

import vw from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

function Banner(props) {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          
          {
            props.photo && 
            <PhotoLandscape src={props.photo} />
          }

          {
            props.videoSrc && 
            <video src={props.videoSrc} autoPlay muted playsInline loop />
          }
          <Article>
            <h1>{props.title}</h1>
            {
              props.body &&
              <p className="body">
                {props.body}  
              </p>
            }
          </Article>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  background-color: ${theme.color.cc_off_white_bg};
  ${vw('padding-top', 64, 120)}
  ${vw('padding-bottom', 64, 120, 130)}
  ${vw('padding-left', 32, 60, 122)}
  ${vw('padding-right', 32, 60, 170)}

  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
  }
  position: relative;

  video {
    ${vw('width', 256, 647, 620)}
    ${vw('height', 198, 500, 480)}
    border: 8px solid white;
    ${vw('border-width', 8, 12, 14)}
    object-fit: cover;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    filter: drop-shadow(0px 2px 14px rgba(75, 78, 82, 0.10));
  }
`;

const Article = styled.div`
  ${vw('width', 256, 534, 400)}
  ${vw('margin-top', 16, 30, 160)}

  p {
    margin-right: 0;
    ${vw('margin-bottom', 40)}
  }

  h1 {
    ${vw('font-size', 24, 36)};
    ${vw('line-height', 32, 43)}
  }
`;

export default Banner;
