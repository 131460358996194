// Pacakges
import React from 'react';
import styled from 'styled-components';

// Components
import Intl from 'src/components/Intl';
import MarbleHero from 'src/components/MarbleHero';
import Form from './Form';

// Styles
import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Register = () => {
  return (
    <Intl>
      {(t, { locale }) => (
        <MarbleHero
        >
          <Root id="register">
            {locale === 'en' ? (
              <Headline
                src={require('src/assets/images/text/heading-register-now.svg')}
                alt="Register now"
              />
            ) : (
              <Title className="localized">{t.register.title}</Title>
            )}
            <Form />
          </Root>
        </MarbleHero>
      )}
    </Intl>
  );
};

const Root = styled.main`
  padding-left: ${vwMobile(16)};
  padding-right: ${vwMobile(16)};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  @media ${media.tablet} {
    text-align: left;
    padding-left: ${vwTablet(60)};
    padding-right: ${vwTablet(60)};
  }
  @media ${media.desktop} {
    padding-left: ${vwDesktop(280)};
    padding-right: ${vwDesktop(280)};
  }
`;

const Headline = styled.img`
  ${vw('margin-bottom', 40, 80)}
  ${vw('width', 256, 512)}
`;

const Title = styled.h3`
  ${vw('margin-bottom', 40, 80)}
`;


export default Register;
