import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CSSTransition, SwitchTransition } from 'react-transition-group';

// import View360 from './sections/View360';
import Compass from './elements/Compass';
import vw from 'src/styles/utils';
import media from 'src/styles/media';

let clientX, deltaX, angle;

let defaultAngle = 90;
let offset = -135;
let speed = 0.1;

const Views = (props) => {
  const [activeLevelIndex, setActiveLevelIndex] = useState(0);
  const [angle, setAngle] = useState(0);
  const [isShowSwipeToPanImg, setIsShowSwipeToPanImg] = useState(true);
  const rootRef = useRef(null);

  useEffect(() => {
    rootRef.current.style.backgroundPositionX = 0;
    getUpdateAngle(defaultAngle + offset)
  }, [])


  const handleClickLevelButton = (index) => {
    setActiveLevelIndex(index)
  }

  const getUpdateAngle = (angle) => {
    setAngle(angle);
  }

  const hideSwipeToPan = () => {
    setIsShowSwipeToPanImg(false);
  }

  const handleTouchEnd = () => {
    setIsShowSwipeToPanImg(true);
  }

  const handleTouchStart = (e) => {
    clientX = e.touches[0].clientX;
  }

  const handleTouchMove = (e) => {
    let slidePositionXValue = parseInt(rootRef.current.style.backgroundPositionX, 10);

    deltaX = speed*(e.changedTouches[0].clientX - clientX);
    rootRef.current.style.backgroundPositionX = `${deltaX+slidePositionXValue}px`;

    calculateAngle(-1*(deltaX+slidePositionXValue));

    hideSwipeToPan();
  }

  const calculateAngle = (nowPositionX) => {
    var image = new Image();
    getUpdateAngle(360*nowPositionX/image.width%360+offset)
  }

  return (
    <Root>
      <Title>
        tranquil <br/>living <br/>
        and urban <br/>
        convenience<br/>
      </Title>
      <Compass angle={angle} activeLevelImage={require('src/assets/images/floorplans/view/compass.svg')} activeLevelIndex={29} />
      {/* <SwitchTransition>
        <CSSTransition key={29} timeout={200} classNames="fade"> */}
          {/* <View360
            src={require('src/assets/images/floorplans/view/level_29.jpg')}
            speed={0.75}
            onUpdateAngle={getUpdateAngle}
            onUpdate={hideSwipeToPan}
            onUpdateEnd={handleTouchEnd}
            offset={-135}
          /> */}
        {/* </CSSTransition>
      </SwitchTransition> */}
      <SwipeToPan show={isShowSwipeToPanImg} src={require('src/assets/images/floorplans/swipe_to_pan.svg')} alt="swipt to pan" />
      
      <View360
        ref={rootRef} 
        onTouchStart={handleTouchStart} 
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleTouchStart} 
        onMouseMove={handleTouchMove}
        onMouseUp={handleTouchEnd}
      />
    </Root>
  )
}

Views.propTypes = {

}

const Root = styled.main`
  width: 100%;
  ${vw('height', 380, 680)};
  position: relative;
`;

const View360 = styled.div`
  width: 100%;
  height: 100%;
  background: url(${require('src/assets/images/floorplans/view/level_29.jpg')});
  background-size: cover;
  background-repeat: repeat-x;
`;

const Title = styled.h1`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 9;
  pointer-events: none;
  color: white;
  ${vw('font-size', 22, 38)};
  ${vw('line-height', 28, 42)};

  @media ${media.tablet} {
    top: 60px;
    left: 60px;
  }

  @media ${media.desktop} {
    left: 90px;
  }
`;

const MenuIcon = styled.img`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate3d(0, -50%, 0);
  cursor: pointer;
`;

const SwipeToPan = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: ${props => props.show ? '1' : '0'};
  pointer-events: none;
  transition: all 0.3s ease-out;

  @media ${media.desktop} {
    top: 20%;
  }
`;

export default Views
