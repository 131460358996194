import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Intl from 'src/components/Intl';
import Button from 'src/components/Button';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Page = (props) => {
  const { header, headerWidth, headerText, text, localized } = props;
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <Container>
            {localized && locale !== 'en' ? (
              <h1 className="localized">{headerText}</h1>
            ) : (
              <Header src={header} alt={headerText} width={headerWidth} />
            )}
            <p className="body">{text}</p>
            <Button
              text={t.backButton}
              to={getLocaleURL() === '' ? '/' : getLocaleURL()}
              large
            />
          </Container>
        </Root>
      )}
    </Intl>
  );
};

Page.propTypes = {
  header: PropTypes.string,
  headerWidth: PropTypes.string,
  headerText: PropTypes.string,
  text: PropTypes.string,
  localized: PropTypes.bool,
};

const Root = styled.main`
  width: 100%;
  min-height:100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${require('src/assets/images/woodgrain-background.jpg')});
  background-size: cover;
  background-position: center;
  justify-content: center;
  text-align: center;

  p {
    ${vw('margin-top', 20, 40)}
    ${vw('margin-bottom', 20, 40)}
    @media ${media.desktop} {
      max-width: ${vwDesktop(540)};
    }
  }
`;

const Container = styled.div``;

const Header = styled.img`
  display: block;
  margin: auto;
  ${(props) => vw('width', props.width / 2, props.width)}
`;

export default Page;
