import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import theme from 'src/styles/theme';
import vw, { vwDesktop } from 'src/styles/utils';

const LanguageSwitcher = (props) => {
  return (
    <Intl>
      {(t, { locale }) => (
        <Root {...props}>
          <StyledLink to="/" active={locale === 'en'} className="en">
            EN
          </StyledLink>
          <StyledLink to="/tc" active={locale === 'tc'}>
            中文
          </StyledLink>
          <StyledLink to="/ko" active={locale === 'ko'}>
            한국어
          </StyledLink>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  margin: auto;
  ${vw('width', 119)}
  ${vw('margin-top', 40, 100, 0)}
  justify-content: space-between;
  align-items: center;
  display: flex;
  transition: 0.2s all ease;
  position: relative;

  &.show-on-desktop {
    display: none;
  }

  @media ${media.desktop} {
    &.show-on-desktop {
      align-items: baseline;
      grid-column: 3 / 4;
      grid-row: 1 / 2;
      display: flex;
      margin-left: auto;
      margin-right: ${vwDesktop(33)}
    }

    &.show-on-tablet {
      display: none;
    }
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  font-weight: 500;
  color: ${(props) => props.active ? theme.color.blue : theme.color.grey_dark};
  font-family: ${theme.font.family};
  ${vw('font-size', 13)}
  ${vw('letter-spacing', 1)}

  &.en {
    ${vw('letter-spacing', 1)}
  }

  ${(props) => props.active && 
    `&:after {
      content: '';
      display: block;
      position: absolute;
      background-color: ${theme.color.blue};
      width: 97%;
      height: 1px;
      bottom: -4px;
    }`
  }
`;

export default LanguageSwitcher;