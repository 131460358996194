module.exports = {
  defaultLocale: 'en',
  locales: ['en', 'tc', 'ko'],
  prerender: true,
  analytics: {
    gtag: '',
    gtmId: 'GTM-MZ2VKWV',
    optimizeId: '',
    hotjarId: '',
  },
  seo: {
    title: 'Precidia | New Condos in West Coquitlam',
  },
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1440,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
  },
};
