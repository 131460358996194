import React from 'react';

export default {
  pages: {
    floorplans: '戶型圖',
    interiors: '室內設計',
    amenities: '休閒設施',
    neighbourhood: '社區介紹',
    gallery: '圖庫',
    developer: '發展商',
    register: (
      <>
        <span className="hide-on-mobile">立即</span>登記
      </>
    ),
  },
  bookAppointment: (
    <>
      Book
      <br /> Appointment
    </>
  ),
  backButton: '回到首頁',
  home: {
    hero: {
      script: '登峰造極的生活體驗',
      title:
        '樓高 29 層的 Precidia，傲居西高貴林的黃金中心地段，完美呈現 178 套 1 房加書房至 3 房之精品住宅。',
      text: '傲居黃金地段的 Precidia，尊享四通八達的便捷，位處 Cottonwood Avenue 夾 Whiting Way 的靜謐住宅區內，將優雅環境及都市便利天衣無縫地完美融合，讓您佔盡地利之便，享受每一天的精彩生活!',
      button: '瀏覽戶型圖',
    },
    content1: {
      title: (
        <>
          私人殿堂 <br />
          有口皆碑
        </>
      ),
      text: '從時尚特色、經典配置，到頂級的家電組合， Ledingham McAllister精心打造的每間住宅，都因卓越品質及物超所值而廣獲好評。',
      button: '室內設計',
    },
    content2: {
      title: (
        <>
          講究細節 <br />
          體現卓越
        </>
      ),
      text: '從豪華的社區入口，到匠心規劃的每個休閒空間，我們的不懈使命，就是讓每位業主能更充分地享受生活。',
      button: '休閒設施',
    },
    content3: {
      title: (
        <>
          靜謐便捷
          <br />
          兩者兼具
        </>
      ),
      text: '座落在靜謐住宅區的 Precidia，尊享四通八達的便捷，優雅環境及都市便利在此天衣無縫地完美融合。捷運站、商店街、美食餐廳等各種 便利服務，皆在徒步之遙。Precidia 得天獨厚的地利之便，讓您在工作與生活之間，找到最適合的平衡節奏!',
      button: '社區介紹',
    },
    backHome: '回到首頁',
  },
  floorplans: {
    title: <>178 套一房加書房至三房的溫馨系列住宅</>,
    header: {
      plan: '平面圖',
      type: '種類',
      sqft: '平方呎',
    },
    types: {
      oneBedOneDen: '一房 + 書房',
      twoBed: '兩房',
      threeBed: '三房',
    },
    downloadPlan: '下載平面圖',
    bed: '房',
    den: '書房',
    bath: '衛浴',
    indoor: '室內生活空間',
    outdoor: '戶外生活空間',
    approx: '約',
    total: '整體空間',
    back: '<  回到所有戶型圖',
  },
  interiors: {
    hero: {
      title: '私人殿堂 有口皆碑',
      intro:
        '從開放式空間安排、超高天花板，以至能讓北岸山脈及菲莎河谷之天然美景盡收眼底的寬敞景觀窗戶，Precidia 之一房加書房到三房的 系列精品住宅，對細節格外講究，呈現登峰造極的居家生活。',
      body: (
        <>
          <p className="body">天花板高達 9’，並附設空調</p>
          <p className="body">鋪設石英檯面及卡拉拉大理石防濺水牆的現代化廚房</p>
          <p className="body">
            以全尺寸、含 5 爐頭天然氣灶台的不鏽鋼金屬 家電組烹飪美食、招待親友
          </p>
          <p className="body">全尺寸的前置式節能 Whirlpool 洗衣及乾衣機</p>
          <p className="body">
            舒適的衛浴套間取材自私人水療中心，搭配豪
            華懸空式盥洗台、石英檯面及櫥櫃下照明系統
          </p>
          <p className="body">寬敞的半開放式陽台，打造無縫戶外生活</p>
        </>
      ),
    },
    banner: {
      title: '經典裝潢',
      body: '由大師匠心設計的兩款室內色調，分別為時尚 亮眼的栗色主題色調，以及現代自然的象牙色主題色調，讓您依照喜好，為生活空間增添個人色彩!',
      drag: 'drag',
      dark: '栗色主題色調',
      light: '象牙色主題色調',
    },
    banner2: {
      text: '從時尚特色、經典配置，到頂級的家電組合， Ledingham McAllister 精心打造的每間住宅，都因卓越品質及物超所值而廣獲好評。',
      buttonLabel: '下載特色介紹',
    },
  },
  amenities: {
    hero: {
      title: '講究細節 體現卓越',
      intro:
        '夥拍屢獲殊榮的建築團隊 IBI Group ，從豪華的社區入口，到匠心規劃的各個休閒空間，Precidia 的各種精心設計，對細節格外講究。我們的不懈使命是讓每位業主皆能更充分地享受生活。傲居西高貴林的黃金中心地段， Precidia 完美呈現無與倫比的生活體驗。',
      body: (
        <>
          <p className="body">建築設計由屢獲殊榮的建築團隊 IBI Group 操刀</p>
          <p className="body">結合華麗水景、優美造景及寬敞車道的豪華社區入口</p>
          <p className="body">設置豪華爐畔休息區的超高迎賓大堂</p>
          <p className="body">隨時迎接您歸來的專屬禮賓服務</p>
          <p className="body">安全訪客停車位</p>
          <p className="body">令遠道而來的外地親友感到賓至如歸的訪客套房</p>
        </>
      ),
    },
    banner1: {
      title: '休閒娛樂 更上一層',
      text: 'Precidia 坐擁 10,000 平方呎的完善休閒設 施。您只需要搭上電梯，就可以抵達專屬樓層 ，享受各式各樣多元設施。除了可以款待親友 之外，若賓客遠道而來，需要棲身之所，這裡 還附設一個傢俱齊全的訪客套房。',
    },
    banner2: {
      title: '流連忘返 樂不思蜀',
      body: (
        <>
          <p className="body">
            設有爐畔休息區、桌球檯、設備完善的主廚廚房以及獨立宴會廳的專屬溫馨社交俱樂部
          </p>
          <p className="body">附設爐畔休息區、用餐區及兒童遊戲區的頂樓露台</p>
          <p className="body">設備完善的健身中心</p>
          <p className="body">
            為遞送包裹、儲藏單車及維修區之用的多個多功能空間
          </p>
          <p className="body">狗兒清潔美容區</p>
        </>
      ),
    },
    banner3: {
      title: '溫馨天地 登峰造極',
    },
    ground: '一樓',
    lobby: '迎賓樓層',
    amenity: '休閒設施樓層',
    lobbyEntrance: '大堂入口',
    rooftop: '休閒樓層最頂層',
    autoCourt: '車道入口',
    grandLobbyEntrance: '豪華迎賓大堂入口',
    concierge: '禮賓部',
    fitnessCentre: '健身中心',
    kitchen: '主廚廚房',
    billards: '桌球檯',
    outdoorLounge: '戶外休息區',
  },
  neighbourhood: {
    hero: {
      title: '靜謐便捷 相輔相成',
      subtitle:
        'Precidia 傲居西高貴林黃金中心地段，位處西高貴林 Cottonwood Avenue 夾 Whiting Way 的靜謐住宅區內，讓您以地利之便，尊享每一天的精彩生活!',
    },
    script: '多彩生活 令人著迷',
    banner1: {
      text: '捷運站、商店街、美食餐廳等各種便利服務，皆只在徒步之遙。Precidia 以得天獨厚的地利之便，讓您在工作與生活之間，找到最適合的平衡節奏!',
    },
    banner2: {
      text: '無論您鍾情於戶外運動，或只想追求靜謐生活，Precidia 都能讓您盡享一切美好。',
    },
    banner3: {
      text: '冒險愛好者總是不遠千里前來探索西高貴林的迷人景致。他們會深受吸引，必有箇中道理。',
    },
    map: {
      categories: {
        shopping: '購物',
        parks: '公園及休閒去處',
        restaurants: '餐廳及咖啡廳',
        schools: '學校',
        projects: 'Ledmac 項目',
      },
    },
  },
  gallery: {
    hero: {
      title: '體現 Ledingham McAllister 對西高貴林的願景',
      caption: '車道入口',
    },
    banner1: {
      title: '生活體驗 非凡卓越',
    },
    banner2: {
      title: 'Precidia 呈現登峰造極的居家生活',
    },
    banner3: {
      title: (
        <>
          Precidia 以得天獨厚的地利之便，
          <br className="hide-on-mobile" />
          讓您在工作與生活之間，
          <br className="hide-on-mobile" />
          找到最適合的平衡節奏!
        </>
      ),
    },
  },
  developer: {
    hero: {
      title: '信心 · 卓越 · 超值',
      body: 'Ledingham McAllister 致力於智能開發項目，選擇尚佳的位置，創造超羣嶄新的住宅社區。百多年來，在設計、環境領導、工程及建築等項目上，我們一直信守著嚴格的標準，保證所 有住宅均有恆久的質素和高值。建造超過 18,000 個單位，我們可以自豪地宣稱，憑藉著我們過去建立的卓越口碑，我們定能開創精彩未來。',
    },
    companies: {
      ibi: {
        description:
          'IBI GROUP現已成為ARCADIS一份子。ARCADIS GROUP是一家科技驅動的設計公司，近50年來一直為客戶提供建築設計 、工程、規劃、系統和技術服務。我們在全球60多個辦事處擁有2700多名專業人員，通過將我們的智慧實踐中的既定能力引入我們的覈心建築和基礎設施業務，我們創造了快速回應的彈性空間和智慧城市環境。從高樓到醫院，從交通系統到學校，我們塑造著人們在未來都市中生活，工作，移動，學習和療癒的方式。',
      },
      pwl: {
        description:
          'PWL Partnership 的精英團隊包括景觀設計師、城市設計師和規劃師。過去 40 年間，他們在加拿大，美國和亞洲建立卓越口碑。 其團隊包含 5 位合夥人，4 位準合夥人，以及 30 名景觀設計師和建築師。 PWL 紮根溫哥華 ，致力與客戶、項目團隊和本地相關人士持續建立良好關係。該公司設計經驗豐富，範疇包括大型海濱社區項目、綠色通道、公交走廊、城市廣場、街道景觀、鄰里公園及停車場等。',
      },
      themill: {
        description:
          'Mill Design Group Inc. 由室內設計師 Janine Wilson 於 2011 年創立。Mill Design Group Inc. 位於美麗的北溫哥華，專門從事多單位住宅設計。憑藉在室內設計行業超過 18 年的經驗，Janine 和她出色的團隊與發展商共同合作，創造出既能反映社區特色，又能滿足買家審美及價值觀的室內設計和裝修。他們對室內裝修的設計理念，以經典及易於保養出發，同時講究風格及舒適性。',
      },
    },
  },
  footer: {
    column1: {
      title: '聯繫我們',
      buttonLabel: '立即登記',
    },
    column2: {
      title: '展銷中心',
      text: (
        <>
          每天 12-5 PM 開放
          <br />
          (周五休息)
          <br />
          卑詩省本拿比 North 路 3355 號 150 室
        </>
      ),
      buttonLabel: '即刻預約',
    },
    column3: {
      title: '項目地點',
    },
    marketing: 'marketing in association with',
    disclaimer:
      '*Limited time, prices, incentives, and commissions are subject to change without notice. The 3% cash back will be shown as credit on statement of adjustments upon completion. Ask a sales representative for more details. The information contained in this promotional material is for illustration purposes only and is subject to change without notice. Maps and renderings are an artistic representation only. The quality residences at Precidia are developed by LM Precidia Homes Limited Partnership. The developer reserves the right to make modifications or substitutions should they be necessary. This is currently not an offering for sale. Any such offering can only be made by way of a disclosure statement. E. & O.E.',
    corporate: {
      title: '信心 · 卓越 · 超值',
      text: 'Ledingham McAllister 致力於在黃金地段打造優質住宅，持續為客戶開創長期價值。我們秉持最高標準，憑藉我們過去建立的卓越口碑，我們定能開創精彩未來。',
      privacy: '私隱政策',
    },
  },
  register: {
    title: '立即登記',
    firstname: '名字 *',
    lastname: '姓氏 *',
    email: '電郵 *',
    phone: '電話 *',
    zip: '郵編 *',
    select: '請選擇',
    buyRent: '您是打算購買還是租用? *',
    buyRentOptions: [
      {
        label: '購買',
        value: 'Buy',
      },
      {
        label: '租用',
        value: 'Rent',
      },
    ],
    howHeard: '您如何得知有關 Precidia 的訊息? *',
    howHeardOptions: [
      {
        label: '網路廣告',
        value: 'Online Ads',
      },
      {
        label: '中文廣告',
        value: 'Chinese Ads',
      },
      {
        label: '韓文廣告',
        value: 'Korean Ads',
      },
      {
        label: '印刷廣告',
        value: 'Print Advertising',
      },
      {
        label: '社交網站',
        value: 'Social Media',
      },
      {
        label: '廣告招牌',
        value: 'Signage',
      },
      {
        label: '地產經紀',
        value: 'Realtor',
      },
      {
        label: '親友告知',
        value: 'Friend & Family',
      },
      {
        label: '其他',
        value: 'Other',
      },
    ],
    whatInquire: '您對何種房型有興趣?',
    whatInquireOptions: [
      {
        label: '1 房',
        value: 'One bedroom',
      },
      {
        label: '2 房',
        value: 'Two bedroom',
      },
      {
        label: '3 房',
        value: 'Three bedroom',
      },
      {
        label: '頂樓',
        value: 'Penthouses',
      },
    ],
    realtor: '您是否為地產經紀?*',
    workingWithRealtor: '您是否已有合作的地產經紀?',
    yes: '是',
    no: '否',
    ifYesRealtor: `如果您是地產經紀，或者已有合作的地產經紀，請提供所屬的地產公司名稱，或者地產經紀的名字:`,
    disclaimer:
      '我願意收到來自 Ledingham McAllister 以電子通訊方式提供有關最新項目、搶先體驗及優惠活動等相關訊息。*',
    submit: '提交',
    required: '*必填欄位',
  },
  thankYou: {
    title: '感謝您的支持',
    text: '感謝您登記由 Ledingham McAllister 精心打造的 Precidia。我們已收到您提交的信息，將安排專人儘快與您聯繫。',
  },
  notFound: {
    text: '發生錯誤，您欲前往的網頁並不存在。',
  },
  privacy: {
    title: '私隱政策',
  },
};
