import React, { Component } from 'react';
import PropTypes from 'prop-types';
import interact from 'interactjs';

class View360 extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    defaultAngle: PropTypes.number,
    offset: PropTypes.number,
    speed: PropTypes.number,
    onUpdate: PropTypes.func,
    onStart: PropTypes.func,
    onEnd: PropTypes.func,
    settings: PropTypes.object, // interactjs settings
  };

  static defaultProps = {
    src: '',
    defaultAngle: 180,
    offset: 0,
    speed: 1,
    onUpdate: (f) => f,
    onStart: (f) => f,
    onEnd: (f) => f,
    settings: {},
  };

  constructor(props) {
    super(props);
    const { defaultAngle, offset } = this.props;
    this.angle = defaultAngle;
    this.state = {
      position: `${((defaultAngle + offset) / 360) * 100}%`, // initial background position
    };
    this.$node = React.createRef();
  }

  componentDidMount() {
    this.swipeStarted = false;
    this.image = new Image();
    this.image.src = this.props.src;
    this.image.onload = () => {
      this.handleImageLoad();
      window.addEventListener('resize', this.handleResize.bind(this));
    };

    if (this.$node.current) {
      interact(this.$node.current).draggable(
        Object.assign(
          {
            inertia: true,
            onstart: this.handleDragStart,
            onmove: this.handleDragMove,
            onend: this.handleDragEnd,
          },
          this.props.settings
        )
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.handleNewSrc(this.props.src);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  updateImageViewWidth() {
    this.imageViewWidth =
      (this.$node.current.clientHeight / this.image.height) * this.image.width;
  }

  handleDragStart = () => {
    this.props.onStart();
  };

  handleDragEnd = () => {
    this.props.onEnd();
  };

  handleNewSrc = (src) => {
    this.image = new Image();
    this.image.src = src;
    this.image.onload = this.handleImageLoad;
  };

  handleImageLoad = () => {
    this.updateImageViewWidth();
    let percentage = 1 - (1 - (this.angle + this.props.offset) / 360);
    let position =
      this.imageViewWidth * percentage + this.$node.current.clientWidth * 0.5;

    this.setState(
      {
        position,
      },
      () => {
        this.calculateAngle();
      }
    );
  };

  handleResize = () => {
    this.calculateAngle();
  };

  handleDragMove = (e) => {
    const newPosition = this.state.position + e.dx * this.props.speed;
    this.setState({
      position: newPosition,
    });
    this.calculateAngle();
  };

  calculateAngle = () => {
    this.updateImageViewWidth();
    let percentage =
      ((this.state.position - this.$node.current.clientWidth * 0.5) /
        this.imageViewWidth) %
      360;
    this.angle = ((percentage * 360) % 360) - this.props.offset;
    this.props.onUpdate(this.angle);
  };

  render() {
    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          backgroundImage: `url(${this.props.src}`,
          backgroundPositionX: `${this.state.position}px`,
          backgroundSize: 'cover',
          touchAction: 'none',
        }}
        ref={this.$node}
      />
    );
  }
}

export default View360;
